@use 'mixins' as m;
@use 'variables' as v;

@mixin article-number-common {
  display: inline-block;
  font-size: 14px;

  .article-number {
    display: inline-flex;
    vertical-align: middle;
    width: 100%;
    line-height: 1.4em;

    &__icon {
      line-height: 1.85em;
      height: 1.4em;
      width: 1.7em;
      vertical-align: middle;
      margin-right: 0.25 * v.$default-thickness;
      flex: none;
    }

    &__value {
      flex: 0 1 auto;

      @include m.text-truncate;
      direction: rtl; // ellipsis to the left
    }
  }
}
