@use 'material/custom-styles/dialog' as dialog-custom-styles;

@use 'colors' as c;

@mixin image-gallery-overlay-global {
  .image-gallery-overlay {
    width: 90%;
    height: 90%;

    .mat-mdc-dialog-container {
      position: relative;
      @include dialog-custom-styles.dialog-container-background-color(c.$color-gray-25);
    }
  }
}
