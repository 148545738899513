@use 'material/custom-styles/dialog' as dialog-custom-styles;

@mixin change-condition-status-dialog-global {
  .mpctm-change-condition-status-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(white);
      max-width: 80vw;
    }
  }
}
