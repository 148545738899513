@use 'colors' as c;
@use 'variables' as v;

@mixin file-type-tile($theme) {
  $color-gray: c.$color-gray-600;
  $color-light-gray: c.$color-gray-50;

  $color-active-foreground: c.$color-accent-500;
  $color-active-background: c.$color-accent-250;

  $color-pending-foreground: c.$color-state-orange-500;
  $color-pending-background: c.$color-state-orange-100;

  .mpcm-file-type-tile {
    --icon-color: #{$color-gray};
    --tile-border-color: #{$color-light-gray};
    --tile-background-color: white;

    font-size: 36px;

    &--active {
      --icon-color: #{$color-active-foreground};
      --tile-border-color: #{$color-active-foreground};
    }

    &--warn {
      --icon-color: #{$color-pending-foreground};
      --tile-border-color: #{$color-pending-foreground};
    }

    .tile {
      border: 2px solid var(--tile-border-color);
      border-radius: 4px;
      background-color: var(--tile-background-color);
      overflow: hidden;

      &::before,
      &::after {
        border-top: 1px solid $color-light-gray;
      }

      &__icon {
        color: var(--icon-color);
        font-size: inherit;
        width: 1em;
        height: 1em;
        background: var(--tile-background-color);
      }
    }
  }
}

:host {
  display: block;

  .tile {
    display: flex;
    padding: 0.25em;
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 150%;
      position: absolute;
      left: -1px;
      box-sizing: border-box;
      height: 1px;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: top left;
      top: -2px;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: bottom left;
      bottom: -2px;
    }

    &__icon {
      z-index: 1;
    }
  }
}
