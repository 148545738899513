@use 'variables' as v;

@mixin paginator-default-styles {
  .mat-mdc-paginator {
    .mat-mdc-paginator-outer-container {
      padding: 0.5 * v.$default-thickness 0;
    }

    .mat-mdc-paginator-page-size-select {
      width: 64px;
    }

    .mat-mdc-icon-button {
      --mdc-icon-button-icon-size: 28px;
    }
  }
}
