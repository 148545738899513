@use '../custom-styles/tabs' as tabs-custom-styles;

@use 'colors' as c;
@use 'variables' as v;

@mixin tabs-default-styles {
  .mat-mdc-tab-group,
  .mat-mdc-tab-nav-bar {
    @include tabs-custom-styles.tab-active-color(c.$tab-active-color);

    @include tabs-custom-styles.tab-inactive-label-color(c.$tab-inactive-color);

    --mat-tab-header-active-ripple-color: #{c.$tab-ripple-color};
    --mat-tab-header-inactive-ripple-color: #{c.$tab-ripple-color};

    .mdc-tab {
      min-width: 160px;

      &:hover {
        .mdc-tab__ripple::before {
          opacity: 0;
        }
      }
    }
  }

  .mat-mdc-tab-group {
    .mat-mdc-tab-body-content {
      height: unset;
      overflow: hidden;
      // NOTE - some small side padding is needed so that box-shadow of tab elements is not clipped
      padding: v.$default-thickness 2px;
      box-sizing: border-box;
    }
  }

  .mat-mdc-tab-group:not(.no-divider) .mat-mdc-tab-header,
  .mat-mdc-tab-nav-bar:not(.no-divider) {
    border-bottom: 1px solid c.$color-gray-100;
  }
}
