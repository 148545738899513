@use 'material/custom-styles/dialog' as dialog-custom-styles;
@use 'material/custom-styles/radio' as radio-custom-styles;
@use 'material/custom-styles/form-field' as form-field-custom-styles;

@use 'colors' as c;
@use 'variables' as v;

@mixin file-info-dialog($theme) {
  $color-gray: c.$color-gray-600;
  $color-light-gray: c.$color-gray-100;

  $bg-light-gray-color: c.$color-gray-50;

  $color-focus: c.$color-gold-25;
  $color-accent: c.$color-accent-500;

  .mpcm-file-info-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(white);
      max-width: 80vw;
    }

    .dialog-content {
      &__form-label {
        margin-left: 0.5 * v.$default-thickness;
      }
    }

    // NOTE: Additional h2 tag can be deleted when this component's styles will be modernized
    h2.dialog-title {
      color: $color-gray;
      text-align: center;
      margin-bottom: v.$default-thickness * 2;
    }

    .mat-mdc-form-field.dialog-content {
      &__comment {
        @include form-field-custom-styles.form-field-background-color(transparent);
        margin: 0;

        textarea {
          background-color: $bg-light-gray-color;
          border-radius: 4px 4px 0 0;
          padding: v.$default-thickness;
          box-sizing: border-box;
        }

        mat-label {
          color: $color-gray;
          font-size: 14px;
          line-height: 48px;
        }
      }
    }

    .option {
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $color-focus;
      }

      .option__label.option__label--active {
        color: $color-accent;
        user-select: none;
      }

      &__label {
        color: $color-gray;
        font-weight: bold;
        font-size: 14px;
      }

      .option__radio-button {
        @include radio-custom-styles.radio-unselected-outer-circle-color($color-light-gray);
      }
    }
  }
}

:host {
  display: block;
  padding: 3.5 * v.$default-thickness 3.5 * v.$default-thickness 1.5 * v.$default-thickness;

  .dialog-title {
    padding: 0;
  }

  .dialog-content {
    padding: 0;
    &__options {
      display: flex;
    }

    &__comment {
      @include form-field-custom-styles.form-field-block-padding(0px);
      @include form-field-custom-styles.form-field-inline-padding(0px);
    }
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: v.$default-thickness 4px;
    gap: 4px;
    min-width: 0;

    &__label {
      max-width: 100%;
    }

    .mpcm-file-type-tile {
      margin-bottom: v.$default-thickness * 0.5;
    }
  }

  .dialog-actions {
    padding: 0.5 * v.$default-thickness 0;
  }

  .dialog-submit {
    flex-grow: 1;
  }
}
