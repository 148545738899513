@mixin multi-selection-filter-global {
  .mp-multi-selection-filter {
    cdk-virtual-scroll-viewport {
      height: 100%;

      .cdk-virtual-scroll-content-wrapper {
        width: 100%;
        height: 100%;
        contain: layout;
      }
    }
  }
}
