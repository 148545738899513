@mixin form-field-text-color($color) {
  --mat-form-field-text-color: #{$color};
}

@mixin form-field-caret-color($color) {
  --mat-form-field-caret-color: #{$color};
}

@mixin form-field-background-color($color) {
  --mat-form-field-container-background-color: #{$color};
}

@mixin form-field-placeholder-color($color) {
  --mat-form-field-placeholder-color: #{$color};
}

@mixin form-field-placeholder-font-weight($font-weight) {
  --mat-form-field-placeholder-font-weight: #{$font-weight};
}

@mixin form-field-min-height($height) {
  --mat-form-field-infix-min-height: #{$height};
}

@mixin form-field-block-padding($padding-top, $padding-bottom: $padding-top) {
  --mat-form-field-padding-top: #{$padding-top};
  --mat-form-field-padding-bottom: #{$padding-bottom};
}

@mixin form-field-inline-padding($padding-left, $padding-right: $padding-left) {
  --mat-form-field-left-padding: #{$padding-left};
  --mat-form-field-right-padding: #{$padding-right};
}

/**
 * Filled form field custom styles
 */

@mixin form-field-filled-underline-color($color) {
  --mdc-filled-text-field-active-indicator-color: #{$color};
  --mdc-filled-text-field-hover-active-indicator-color: #{$color};
}

@mixin form-field-filled-underline-ripple-color($color) {
  --mdc-filled-text-field-focus-active-indicator-color: #{$color};
}

@mixin form-field-filled-hidden-underline {
  --mat-form-field-underline-display: none;
}

@mixin form-field-filled-disabled-background-color($color) {
  --mdc-filled-text-field-disabled-container-color: #{$color};
}

/**
* Outlined form filled custom styles
*/

@mixin form-field-outlined-outline-color($color) {
  --mdc-outlined-text-field-outline-color: #{$color};
  --mdc-outlined-text-field-hover-outline-color: #{$color};
}

@mixin form-field-outlined-disabled-outline-color($color) {
  --mdc-outlined-text-field-disabled-outline-color: #{$color};
}

@mixin form-field-outlined-outline-ripple-color($color) {
  --mdc-outlined-text-field-focus-outline-color: #{$color};
}

@mixin form-field-outlined-leading-border-radius($radius) {
  --mat-form-field-outlined-leading-border-radius: #{$radius};
}

@mixin form-field-outlined-border-radius($radius) {
  --mat-form-field-outlined-leading-border-radius: #{$radius};
  --mat-form-field-outlined-trailing-border-radius: #{$radius};
}

@mixin form-field-outlined-hover-border-width($border-width) {
  --mat-form-field-outlined-hover-border-width: #{$border-width};
}
