@use 'material/custom-styles/button' as button-custom-styles;

@mixin api-key-table-global {
  .mpk-api-key-table {
    .form-field {
      .mat-mdc-icon-button {
        @include button-custom-styles.icon-button-size(30px, 20px);
      }
    }
  }
}
