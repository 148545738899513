@use 'colors' as c;
@use 'variables' as v;

@mixin mp-selector-item($theme) {
  $item-background: c.$color-background-card;
  $item-foreground: c.$color-layout-500;
  $item-hover-background: c.$color-background-hover;

  .mp-selector-item {
    display: flex;
    position: relative;

    margin-bottom: v.$default-thickness;

    &:last-of-type {
      margin-bottom: 0;
    }

    .selection {
      position: absolute;
      right: v.$default-thickness;
      top: v.$default-thickness;

      &__indicator {
        color: c.$color-gray-150;
      }
    }

    .card {
      flex: 1;
      text-decoration: none;
      border-radius: 4px;
      box-shadow: v.$card-box-shadow;
      color: $item-foreground;
      background: $item-background;
      padding: v.$default-thickness;
      transition: v.$default-transition;
      cursor: pointer;

      &:hover {
        background: $item-hover-background;
        transition: v.$default-transition;
      }

      &:visited,
      &:focus {
        color: $item-foreground;
      }

      &.active {
        background: $item-hover-background;
        transition: v.$default-transition;
      }

      // FIXME - this component should not know anything abut the projected content
      h4 {
        margin-bottom: v.$default-thickness * 0.5;
        margin-top: 0;
      }

      p {
        margin: 0;
      }

      h4:only-child {
        margin: 0;
      }
    }

    .disabled {
      cursor: default;
      pointer-events: none;

      // TODO: Styling für "disabled" state ergänzen!
      //       @Alexander Carapezza

      opacity: 0.3;
    }
  }
}
