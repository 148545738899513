@import 'quill/dist/quill.snow.css';

@mixin rich-text-editor($theme) {
  // Theme related styles and quill overrides
  .mp-rich-text-editor {
    .rich-text-editor {
      // Quill styles overrides
      .ql-editor {
        min-height: 100px;
      }

      .ql-tooltip {
        z-index: 1;

        &[data-mode='link']::before {
          content: 'Link hinzufügen:';
        }

        &[data-mode='image']::before {
          content: 'Bild einfügen:';
        }

        &[data-mode='image'],
        &[data-mode='link'] {
          .ql-action::after {
            content: 'Speichern';
          }
        }

        // Labels for link edit tooltip
        &:not([data-mode]) {
          &::before {
            content: 'Link-URL:';
          }

          .ql-action::after {
            content: 'Bearbeiten';
          }

          .ql-remove::before {
            content: 'Löschen';
          }
        }
      }
    }
  }

  // Layout styles
  .mp-rich-text-editor {
    display: block;

    .rich-text-editor {
      width: 100%;

      &__toolbar--hidden {
        display: none;
      }
    }
  }
}
