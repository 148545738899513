@use 'colors/utilities' as color-utils;

@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin _button-colors($color-primary, $color-accent, $color-warn) {
  &.mat-primary {
    @content ($color-primary);
  }

  &.mat-accent {
    @content ($color-accent);
  }

  &.mat-warn {
    @content ($color-warn);
  }
}

@mixin button-default-styles {
  .mat-mdc-raised-button {
    &:not(:disabled) {
      --mdc-protected-button-label-text-color: #{c.$color-foreground-text};

      @include _button-colors(c.$color-primary-contrast-500, c.$color-accent-contrast-500, c.$color-warn-contrast-500)
        using ($color) {
        --mdc-protected-button-label-text-color: #{$color};
      }
    }

    --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
    --mdc-protected-button-disabled-label-text-color: #{c.$color-foreground-disabled-text};
  }

  .mat-mdc-outlined-button {
    &:not(:disabled) {
      --mdc-outlined-button-outline-color: #{c.$color-foreground-divider};
    }

    --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-unelevated-button {
    &.mat-unthemed {
      --mdc-filled-button-label-text-color: #{c.$color-foreground-text};
    }

    @include _button-colors(c.$color-primary-contrast-500, c.$color-accent-contrast-500, c.$color-warn-contrast-500)
      using ($color) {
      --mdc-filled-button-label-text-color: #{$color};
    }

    --mdc-filled-button-disabled-label-text-color: #{c.$color-foreground-disabled-text};
    --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-fab {
    &:not(:disabled) {
      @include _button-colors(c.$color-primary-contrast-500, c.$color-accent-contrast-500, c.$color-warn-contrast-500)
        using ($color) {
        --mat-fab-foreground-color: #{$color};
      }
    }
  }

  .mat-mdc-mini-fab {
    &:not(:disabled) {
      @include _button-colors(c.$color-primary-contrast-500, c.$color-accent-contrast-500, c.$color-warn-contrast-500)
        using ($color) {
        --mat-fab-small-foreground-color: #{$color};
      }
    }
  }

  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      $icon-button-size: 40px;

      --mdc-icon-button-state-layer-size: #{$icon-button-size};

      .mat-mdc-button-touch-target {
        width: var(--mdc-icon-button-state-layer-size);
        height: var(--mdc-icon-button-state-layer-size);
      }
    }
  }

  .mat-mdc-button,
  .mat-mdc-outlined-button,
  .mat-mdc-icon-button {
    &.mat-accent {
      --mat-mdc-button-ripple-color: #{color-utils.add-opacity-to-color-variable(c.$color-accent-500, 0.1)};
    }

    &.mat-primary {
      --mat-mdc-button-ripple-color: #{color-utils.add-opacity-to-color-variable(c.$color-primary-500, 0.1)};
    }

    &.mat-warn {
      --mat-mdc-button-ripple-color: #{color-utils.add-opacity-to-color-variable(c.$color-warn-500, 0.1)};
    }
  }

  .mat-mdc-button.mat-mdc-button-base:not(:disabled) {
    color: var(--mdc-text-button-label-text-color, inherit);
  }

  .mat-mdc-button-base {
    & > .mat-icon {
      flex-shrink: 0;
    }

    .mdc-button__label {
      @include m.text-truncate;
    }

    .mat-mdc-button-persistent-ripple {
      display: var(--mat-button-persistent-ripple-display, block);
    }
  }

  .mat-mdc-button .mat-mdc-button-touch-target,
  .mat-mdc-unelevated-button .mat-mdc-button-touch-target,
  .mat-mdc-raised-button .mat-mdc-button-touch-target,
  .mat-mdc-outlined-button .mat-mdc-button-touch-target {
    // Important is needed as there is no easy and clear way to make the selector more specific without overcomplicating it
    height: 100% !important;
  }
}
