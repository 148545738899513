@use 'material/custom-styles/list' as list-custom-styles;

@use 'mixins' as m;
@use 'variables' as v;
@use 'colors' as c;

@mixin datasource-upload-list-item($theme) {
  $color-pending-foreground: c.$color-state-orange-500;
  $color-pending-background: c.$color-state-orange-100;

  $color-text-gray: #757575;

  .mpcm-datasource-upload-list-item {
    @include list-custom-styles.list-item-container-height(58px);

    .list-item-content {
      font-size: 12px;
      color: $color-text-gray;

      &__file-type-tile {
        font-size: 20px;
      }

      &__title-txt {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
      }

      &__subtitle {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        text-decoration: underline;
        color: $color-text-gray;

        &:hover {
          color: $color-pending-foreground;
        }
      }

      &__status {
        &--red {
          background: $color-pending-background;
          color: $color-pending-foreground;
          font-size: 12px;
          line-height: 14px;
          font-weight: bold;
        }
      }

      &__time,
      &__creator {
        text-align: center;
        line-height: 16px;
      }

      &__status-col {
        text-align: right;
      }
    }
  }
}

:host {
  .list-item-content {
    width: 100%;
    align-items: center;
    display: flex;
    gap: v.$default-thickness;

    &__file-type-tile {
      flex: 0 0 auto;
    }

    &__title {
      flex: 1 1 35%;
      width: 0;
    }

    &__title-txt {
      display: block;
      margin-bottom: 2px;
    }

    &__subtitle {
      display: block;
    }

    &__creator {
      flex: 1 1 15%;
      min-width: 0;
    }

    &__time {
      flex: 1 1 15%;
      min-width: 0;
    }

    &__status-col {
      flex: 1 1 15%;
      min-width: 25px;
      display: flex;
      justify-content: flex-end;

      @include m.breakpoint(lg) {
        min-width: 110px;
      }
    }

    &__status {
      &--red {
        padding: 2px 4px;
      }
    }

    &__file-download-button {
      flex: 0 auto;
      min-width: 0;

      &-text {
        display: none;
      }

      &-icon {
        margin: 0;
      }

      @include m.breakpoint(lg) {
        &-text {
          display: inline;
        }

        &-icon {
          margin: v.$basic-button-prefix-icon-margin;
        }
      }
    }
  }
}
