@use 'material/custom-styles/dialog' as dialog-custom-styles;

@mixin delete-confirm-dialog-global {
  .mp-delete-confirm-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(white);
      max-width: 520px;
    }
  }
}
