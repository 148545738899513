@use 'colors/utilities' as color-utils;

@use '../custom-styles/slide-toggle' as slide-toggle-custom-styles;

@use 'variables' as v;

@use 'colors' as c;

@mixin slide-toggle-default-styles {
  mat-slide-toggle.mat-mdc-slide-toggle {
    &,
    &.mat-accent,
    &.mat-warn {
      // Unselected handle
      --mdc-switch-unselected-handle-color: #{c.$color-gray-10};
      --mdc-switch-unselected-hover-handle-color: #{c.$color-gray-50};
      --mdc-switch-unselected-focus-handle-color: #{c.$color-gray-50};
      --mdc-switch-unselected-pressed-handle-color: #{c.$color-gray-50};

      // Disabled unselected handle
      --mdc-switch-disabled-unselected-handle-color: #{c.$color-gray-10};

      // Unselected track
      --mdc-switch-unselected-track-color: #{c.$color-gray-400};
      --mdc-switch-unselected-hover-track-color: #{c.$color-gray-400};
      --mdc-switch-unselected-focus-track-color: #{c.$color-gray-400};
      --mdc-switch-unselected-pressed-track-color: #{c.$color-gray-400};

      --mdc-switch-disabled-handle-elevation-shadow: #{v.$card-box-shadow};
    }

    &,
    &.mat-accent {
      @include slide-toggle-custom-styles.slide-toggle-selected-ripple-color(c.$color-accent-500);
      @include slide-toggle-custom-styles.slide-toggle-selected-handle-color(c.$color-accent-500);
      @include slide-toggle-custom-styles.slide-toggle-selected-track-color(c.$color-accent-200, c.$color-accent-500);
    }

    &.mat-warn {
      @include slide-toggle-custom-styles.slide-toggle-selected-ripple-color(c.$color-warn-500);
      @include slide-toggle-custom-styles.slide-toggle-selected-handle-color(c.$color-warn-500);
      @include slide-toggle-custom-styles.slide-toggle-selected-track-color(c.$color-warn-200, c.$color-warn-500);
    }
  }

  .mdc-switch .mdc-switch__ripple {
    display: var(--mat-slide-toggle-ripple-display, block);
  }
}
