@mixin tab-active-label-color($color) {
  --mat-tab-header-active-label-text-color: #{$color};
  --mat-tab-header-active-hover-label-text-color: #{$color};
  --mat-tab-header-active-focus-label-text-color: #{$color};
}

@mixin tab-inactive-label-color($color) {
  --mat-tab-header-inactive-label-text-color: #{$color};
  --mat-tab-header-inactive-hover-label-text-color: #{$color};
  --mat-tab-header-inactive-focus-label-text-color: #{$color};
}

@mixin tab-active-indicator-color($color) {
  --mdc-tab-indicator-active-indicator-color: #{$color};
  --mat-tab-indicator-active-indicator-color: #{$color};
  --mat-tab-header-active-hover-indicator-color: #{$color};
  --mat-tab-header-active-focus-indicator-color: #{$color};
}

@mixin tab-active-color($color) {
  @include tab-active-label-color($color);
  @include tab-active-indicator-color($color);
}
