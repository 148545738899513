@use 'colors' as c;
@use 'variables' as v;

@mixin mp-selector($theme) {
  $title-bar-background: c.$color-background-dialog;
  $context-bar-background: c.$color-background-card;

  .mp-flyout-selector {
    display: block;
    width: 100%;
    height: 100%;

    background-color: $context-bar-background;
    box-shadow: v.$default-box-shadow;

    &__item-tooltip.mat-mdc-tooltip {
      max-width: 350px;
    }

    .selector-content {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;

      .search-bar {
        flex: 1 0 auto;
        padding: 2 * v.$default-thickness v.$default-thickness 0 v.$default-thickness;
      }

      .context-view-content {
        flex: 0 1 100%;
        overflow-y: auto;
        padding: v.$default-thickness;

        &__virtual-scroll {
          height: 100%;
        }
      }
    }
  }
}
