@mixin artikelsuche-table-view-global {
  .mp-artikelsuche-table-view {
    .article-search-table {
      .select-article-checkbox {
        label {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
