@use 'colors' as c;
@use 'variables' as v;

@mixin klinikkatalog-detail($theme) {
  $border-color: c.$color-layout-50;

  $font-label: 300 #{14px}/#{20px} 'Roboto';
  $font-val: 700 #{16px}/#{20px} 'Roboto';

  $color-text: c.$color-gray-700;
  $color-gray: c.$color-gray-600;
  $color-gray-dark: c.$color-gray-900;

  $item-foreground: c.$color-layout-500;

  $header-color: c.$color-layout-500;

  .mp-klinikkatalog-detail {
    .katalog-head {
      display: flex;

      .katalog-head-main {
        flex: 3;
        display: flex;
        flex-direction: column;

        .action-row__selectors,
        .data-row {
          display: flex;
          flex-direction: row;
        }

        .action-row__selectors {
          width: 80%;
          align-items: center;

          mpsm-catalog-supplier-selector {
            margin: 0;

            .typ-icon h2.katalog-lieferant {
              font-size: 28px;
              color: $item-foreground;
            }

            mp-catalog-type-icon mat-icon {
              color: $item-foreground;
            }
          }

          .lieferant-select,
          .katalogtyp-select {
            flex: 1;
          }

          .katalogtyp-select {
            width: 200px;
            padding: 0 2 * v.$default-thickness;
          }
        }

        .data-row {
          padding-top: v.$default-thickness;

          .item {
            padding-left: 2 * v.$default-thickness;
            display: flex;
            align-items: center;

            mat-icon {
              font-size: 36px;
              width: 36px;
              height: 36px;
              line-height: 40px;
              color: $item-foreground;
            }

            .item__title {
              padding-left: v.$default-thickness;
              padding-bottom: 0;
              font-size: 32px;
              font-weight: bold;
              color: $header-color;
            }

            &:first-child {
              padding-left: 0;
            }

            .item__label {
              color: $color-gray;
            }

            .item__value {
              padding-left: 0.5 * v.$default-thickness;
              font-weight: bold;
            }
          }
        }
      }

      .katalog-head-aside {
        flex: 1;
        display: flex;
        flex-direction: column;

        border: 1px solid $border-color;
        border-radius: 4px;
        padding: v.$default-thickness * 3 v.$default-thickness * 1.5 v.$default-thickness * 1.5;
        background-color: #fff;

        .top-row {
          flex-direction: row;
          display: flex;

          .head-block {
            flex: 1;
            margin-bottom: 2 * v.$default-thickness;

            .head-block__count {
              font-size: 32px;
              line-height: 34px;
              color: $color-gray-dark;
              margin-bottom: 4px;
              padding: 0;
            }

            .head-block__label,
            .head-block__label__covin {
              font-size: 14px;
              letter-spacing: 0.025em;
              text-transform: uppercase;
              color: $color-gray;
            }

            .head-block__label__covin {
              text-align: end;
            }
          }
        }

        .datatable {
          display: flex;
          flex-direction: column;

          .meta-item {
            flex: 0 0 50%;
            display: flex;
            justify-content: space-between;

            padding: v.$default-thickness * 0.25 0;
            margin-bottom: v.$default-thickness * 0.25;
            border-bottom: 1px #ececec solid;

            color: $color-text;

            .meta-item__label {
              font: $font-label;
              display: block;
            }

            .meta-item__value {
              font: $font-val;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
