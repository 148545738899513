@use 'colors' as c;

@mixin two-step-assignment-global {
  .mpk-two-step-assignment {
    .stepper.mat-stepper-vertical {
      display: flex;
      flex-direction: column;

      --mat-stepper-line-color: #{c.$color-gray-75};

      &:not(.stepper--final-step) {
        .mat-step:last-child {
          box-shadow: 0px -5px 25px 0px #00000026;
        }
      }

      .mat-step {
        display: flex;
        flex-direction: column;

        .mat-step-header {
          background-color: white;

          --mat-stepper-header-selected-state-icon-background-color: #{c.$color-layout-500};
          --mat-stepper-header-selected-state-icon-foreground-color: white;
          --mat-stepper-header-selected-state-label-text-color: #{c.$color-layout-500};

          --mat-stepper-header-icon-background-color: #{c.$color-gray-600};
          --mat-stepper-header-icon-foreground-color: white;
          --mat-stepper-header-label-text-color: #{c.$color-gray-600};

          &:hover {
            background-color: var(--mat-stepper-header-hover-state-layer-color);
          }
        }

        .mat-step-label {
          font-weight: bold;
        }

        .mat-step-icon-state-edit + .mat-step-label {
          color: c.$color-accent-500;
        }

        .mat-vertical-content-container {
          flex-grow: 1;
        }
      }

      &.stepper--active-step-2 .mat-step:nth-child(1) {
        .mat-vertical-stepper-content {
          height: auto !important;
          visibility: visible !important;
        }
      }

      // It is quite unfortunate but mat-stepper itself doesn't mark the currently active step in any way
      @for $i from 1 through 2 {
        &.stepper--active-step-#{$i} {
          .mat-step:nth-child(#{$i}) {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
