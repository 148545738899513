@use 'colors' as c;
@use 'variables' as v;
@use 'mixins' as m;

@mixin badge-updates($theme) {
  $text-gray: c.$color-gray-300;
  $color-orange: c.$color-state-orange-500;
  $shady-gray: #f6f6f6;

  .mp-badge-updates {
    color: $text-gray;

    .badge-header {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;

      &__count {
        background-color: $shady-gray;
        color: $color-orange;
        font-weight: bold;
      }

      &__icon {
        font-size: 20px;
        line-height: 20px;
        color: $color-orange;
      }
    }

    &.active {
      color: $color-orange;

      .badge-header {
        font-weight: bold;

        &__count {
          background-color: $shady-gray;
        }
      }
    }
  }
}

:host {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 2px;
  margin-left: v.$default-thickness;

  .badge-header {
    display: inline-block;
    vertical-align: middle;

    &__count {
      border-radius: 10px;
      padding: 2px 6px;
      margin-right: 2px;
    }

    &__icon {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }

    &__label {
      display: none;

      @include m.breakpoint(xl) {
        display: inline;
      }
    }
  }
}
