@mixin slide-toggle-selected-ripple-color($color) {
  --mdc-switch-selected-hover-state-layer-color: #{$color};
  --mdc-switch-selected-focus-state-layer-color: #{$color};
  --mdc-switch-selected-pressed-state-layer-color: #{$color};
}

@mixin slide-toggle-selected-handle-color($color, $disabled-color: $color) {
  --mdc-switch-selected-handle-color: #{$color};
  --mdc-switch-selected-hover-handle-color: #{$color};
  --mdc-switch-selected-focus-handle-color: #{$color};
  --mdc-switch-selected-pressed-handle-color: #{$color};

  // Disabled selected handle
  --mdc-switch-disabled-selected-handle-color: #{$disabled-color};
}

@mixin slide-toggle-selected-track-color($color, $disabled-color: $color) {
  --mdc-switch-selected-track-color: #{$color};
  --mdc-switch-selected-hover-track-color: #{$color};
  --mdc-switch-selected-focus-track-color: #{$color};
  --mdc-switch-selected-pressed-track-color: #{$color};

  // Disabled selected track
  --mdc-switch-disabled-selected-track-color: #{$disabled-color};
}

@mixin slide-toggle-hidden-ripple {
  // NOTE: This is just a temporary solution for hiding slide toggle ripple as the `disableRipple` input is bugged for the time of writing this code
  --mat-slide-toggle-ripple-display: none;
}
