@use 'colors/utilities' as color-utils;

@use 'colors' as c;

@mixin autocomplete-default-styles {
  .mat-mdc-autocomplete-panel {
    // Important is needed as there is no easy and clear way to make the selector more specific due to the panel structure
    padding: 0 !important;
  }
}
