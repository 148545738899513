@use 'colors' as c;
@use 'variables' as v;

@mixin kataloge-flyout($theme) {
  $item-foreground: c.$color-layout-500;
  $title-bar-background: c.$color-background-dialog;

  $text-gray-dark: rgb(36, 36, 36);
  $text-gray-light: #767676;

  $color-green: c.$color-state-green-500;
  $color-yellow: c.$color-state-yellow-500;
  $color-red: c.$color-state-red-500;

  .mp-kataloge-flyout {
    display: block;
    width: 100%;
    height: 100%;

    .title-bar {
      box-shadow:
        0px 2px 1px -1px rgb(0 0 0 / 12%),
        0px 1px 1px 0px rgb(0 0 0 / 8%),
        0px 1px 3px 0px rgb(0 0 0 / 7%) !important;
    }

    .flyout-content {
      @extend .mat-typography;

      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      .filter-section {
        position: relative;

        flex: 0;

        padding: v.$default-thickness;
        background-color: $title-bar-background;

        &__search-field {
          margin-bottom: v.$default-thickness;
        }
      }

      .mp-selector-item {
        margin-bottom: v.$default-thickness;
        display: block;
        border-left: 3px $color-green solid;
        border-radius: 4px 0 0 4px;

        &:first-of-type {
          margin-top: v.$default-thickness;
        }

        .card {
          box-sizing: border-box;
        }

        .selector__info {
          display: flex;
        }

        .selector__title {
          display: flex;

          mat-icon {
            font-size: 18px;
            color: $item-foreground;
            margin: 4px 4px 0 0;
          }
        }

        .selector__label {
          font-size: 14px;
          display: block;
          color: $text-gray-light;
          flex: 0 1 auto;
          min-width: 0;

          & + .selector__label {
            padding-left: 16px;
          }
        }

        h3 {
          color: $item-foreground;
          font-weight: bold;
          margin-bottom: v.$default-thickness * 0.5;
          &:only-child {
            margin-bottom: 0;
          }
        }

        div {
          color: unset;
        }
      }

      .search-results {
        flex: 1;
        padding: 0 v.$default-thickness 0 v.$default-thickness;
      }
    }
  }
}
