@use 'mixins' as m;
@use 'variables' as v;

.properties {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  @include m.breakpoint(lg) {
    font-size: 16px;
  }
}

.property {
  box-sizing: border-box;

  &--full {
    width: 100%;
  }

  &--half {
    width: 50%;
  }

  &--half + &--half {
    padding-left: 8px;
  }

  & + & {
    margin-top: 8px;
  }

  &__label,
  &__value {
    flex: 1;
  }

  &__label {
    color: #ababab;
    font-size: 12px;
    max-width: 100%;

    @include m.text-truncate;

    @include m.breakpoint(lg) {
      padding-right: 1rem;
      font-size: 16px;
    }

    &:after {
      content: ':';
      @include m.breakpoint(lg) {
        display: none;
      }
    }
  }
}
