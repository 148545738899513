@use '@angular/material' as mat;

@use 'colors' as c;
@use 'typography' as t;
@use 'variables' as v;

@mixin mp-basic-flyout($theme) {
  $title-bar-background: c.$color-background-dialog;
  $flyout-background: c.$color-background-background;
  $context-bar-background: c.$color-background-card;

  .mp-flyout {
    position: relative;
    z-index: 2;

    width: 464px;
    height: 100%;

    padding-top: v.$default-toolbar-height;

    .mp-basic-flyout {
      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      background-color: $flyout-background;
      box-shadow: v.$default-box-shadow;

      .title-bar {
        display: flex;
        flex: 0 0 v.$default-toolbar-height;
        align-items: center;

        height: v.$default-toolbar-height;

        padding-left: v.$default-thickness;

        background: $title-bar-background;
        z-index: 3;

        @include mat.m2-typography-level(t.$mp-typography, headline-6);

        .title {
          flex: 1;
        }

        .action-fab {
          position: relative;
          top: 24px;
          right: v.$default-thickness * 2;
        }
      }

      .content {
        position: relative;
        flex: 1;
        height: calc(100% - #{v.$default-toolbar-height});

        &-action-fab {
          position: absolute;

          z-index: 1;

          bottom: 1.5 * v.$default-thickness;
          right: 2 * v.$default-thickness;
        }
      }
    }
  }
}
