@use 'colors' as c;
@use 'variables' as v;

@mixin medical-shop-header-global {
  .mpms-medical-shop-header {
    &__shopping-cart-nav-element {
      // Transition is modified here to match the nav-element color transition
      --shopping-cart-nav-item-badge-border-color-transition: #{v.$default-transition};

      &:hover,
      &--active {
        // NOTE - we need to use a color-mix() here instead of rgba() as resulting badge border color cannot have alpha channel
        $non-transparent-badge-border-color: color-mix(
          in srgb,
          c.$color-background-app-bar,
          c.$color-app-header-element-base-color c.$color-app-header-elements-hover-alpha * 100%
        );
        --shopping-cart-nav-item-badge-border-color: #{$non-transparent-badge-border-color};
      }
    }

    &__search-bar {
      max-width: v.$header-searchbar-max-width;
    }
  }
}
