@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin mp-shell($theme) {
  $full: 100%;
  $toolbar-foreground: c.$color-app-header-text;
  $layout-color-light: c.$color-layout-200;
  $layout-color: c.$color-layout-300;
  $primary-color: c.$color-primary-200;

  .mp-shell {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: $full;
    min-height: $full;
    min-width: $full;
    width: $full;

    .toolbar {
      z-index: 4;
      height: v.$default-toolbar-height;
      color: $toolbar-foreground;
      box-shadow: v.$default-box-shadow;

      &--custom {
        padding: 0;
      }

      @media print {
        display: none;
      }
    }

    .navigation-container {
      flex: 1;
      box-sizing: border-box;

      .sidebar {
        visibility: visible;

        @media print {
          display: none;
        }
      }

      .content {
        @media print {
          margin-left: 0 !important;
        }
      }
    }

    .sidebar-end-wrapper {
      & > * {
        width: 100%;
        box-sizing: border-box;
      }

      &__divider {
        border-color: c.$color-gray-200;
      }

      &__toggle-menu-button.mat-mdc-button {
        &:not(:disabled) {
          color: c.$color-layout-500;
        }

        font-size: 18px;
        font-weight: 300;
        border-radius: 0;

        display: none;
        height: 40px;
        padding-left: v.$navigation-item-padding;

        @include m.breakpoint(sm) {
          display: inline-flex;
          justify-content: flex-start;
        }

        .sidebar-end-wrapper__toggle-menu-button-label {
          padding-left: v.$navigation-item-padding;
        }

        .sidebar-end-wrapper__toggle-menu-icon {
          transform: scaleX(-1);

          @include m.icon-size(24px);
        }
      }
    }

    .sidebar-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__navigation-elements {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
      }
    }

    mat-sidenav {
      .group-content {
        display: none;
      }
    }

    mat-sidenav.mat-drawer-opened {
      width: 340px;

      mp-nav-item a.link.expanded + .group-content {
        display: block;
      }
      mp-nav-item a.link.expanded + .group-content mp-nav-item a:not(.active):before {
        background-color: $layout-color;
      }
    }
  }

  .mp-shell {
    mat-sidenav:not(.mat-drawer-opened) {
      .group-content {
        background: #fff;
        border-radius: 0 4px 4px 0;
        overflow: hidden;
        z-index: 999;
        box-shadow:
          3px 5px 20px rgba(0, 0, 0, 0.15),
          inset 3px 0 0 $layout-color-light;
        padding-bottom: v.$default-thickness * 0.5;

        .group-label {
          padding-left: v.$default-thickness * 2;
          font-size: 14px;
          display: block;
          font-weight: 700;
          color: #b7b7b7;
          line-height: 24px;
          letter-spacing: 0.05em;
          padding: 16px 24px 8px 24px;
          cursor: default;
        }

        mp-nav-item {
          .link {
            height: 46px;
            &:not(.active)::before {
              background-color: $layout-color-light;
            }
            &.active::before,
            &:hover::before {
              background-color: $primary-color;
            }

            mat-icon {
              font-size: 20px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .group-label {
      display: none;
    }
  }

  .mp-shell {
    @include m.breakpoint(sm) {
      mat-sidenav,
      &[style*='visibility: hidden'] {
        width: v.$app-side-nav-width;
        visibility: visible !important;
        display: block !important;
        overflow: visible;
        transform: translateX(0);

        .item__label,
        .group-content {
          display: none;
        }
      }

      mat-sidenav-content {
        margin-left: v.$app-side-nav-width;
      }

      /* Navigation Open */
      mat-sidenav.mat-drawer-opened {
        // NOTE: Transform cannot be set to none as it breaks the animation
        transform: scale(1);

        .item__label {
          display: inline;
        }
      }
    }
  }

  .mp-shell {
    mat-sidenav:not(.mat-drawer-opened) {
      .mp-nav-item[aria-expanded='true'] {
        .group-content {
          display: block;
          position: fixed;
          left: 67px;
          width: 300px;
          top: 0;

          .item__label {
            display: block;
          }
        }
      }
      .mat-drawer-inner-container {
        overflow: visible;

        &:not(.mat-drawer-animating) {
          display: block;
        }
      }
    }
  }
}
