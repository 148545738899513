@use 'colors' as c;
@use 'variables' as v;

@use '../platformcatalog-panel-common';

@mixin plattformkatalog-create($theme) {
  $yellow: c.$color-primary-50;
  $yellow-light: c.$color-primary-10;

  .mp-plattformkatalog-create {
    display: block;
    margin: 0 0 v.$default-thickness * 4 0;

    @include platformcatalog-panel-common.platformcatalog-panel-common;

    .mat-expansion-panel {
      .mat-expansion-panel-header.mat-expanded {
        &,
        &:hover {
          background-color: $yellow;
        }
      }

      .tab-row {
        background-color: $yellow-light;
      }
    }
  }
}
