@use 'colors' as c;

:host {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px 0 0 4px;
}

@mixin entity-item-status($theme) {
  $state-green: c.$color-state-green-500;
  $state-yellow: c.$color-state-yellow-500;
  $state-orange: c.$color-state-orange-500;
  $state-red: c.$color-state-red-500;
  $state-grey: c.$color-state-gray-500;

  mp-entity-item-status {
    background-color: $state-grey;

    &.green {
      background-color: $state-green;
    }

    &.yellow {
      background-color: $state-yellow;
    }

    &.orange {
      background-color: $state-orange;
    }

    &.red {
      background-color: $state-red;
    }
  }
}
