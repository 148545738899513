@mixin snack-background-color($color) {
  --mdc-snackbar-container-color: #{$color};
}

@mixin snack-text-color($color) {
  --mdc-snackbar-supporting-text-color: #{$color};
}

@mixin snack-button-text-color($color) {
  --mat-snack-bar-button-color: #{$color};
}
