@use 'colors' as c;
@use 'variables' as v;

@mixin export-item-row-panel($theme) {
  $color-black: black;

  $color-gray: #424242;

  $color-error: c.$color-state-red-500;

  $color-warning: c.$color-gold-450;

  .mpcm-export-item-row-panel {
    .export-item-panel {
      > .mat-expansion-panel-header {
        height: auto;
        padding: 0;
      }

      span.mat-content {
        margin: 0;
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    .export-item-details {
      font-size: 16px;
      font-weight: normal;

      &__name {
        font-size: 16px;
        color: $color-black;
      }
    }

    .export-item-info {
      font-size: 14px;
      color: $color-gray;

      &__status {
        font-weight: bold;

        &--error {
          color: $color-error;
        }

        &--warning {
          color: $color-warning;
        }
      }
    }
  }
}

:host {
  .panel-header-wrapper {
    display: flex;
    align-items: center;
    gap: v.$default-thickness;
    padding: v.$default-thickness 0.5 * v.$default-thickness;
    width: 100%;

    &__image {
      width: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        transform: scale(2);
      }
    }
  }

  .export-item-details {
    width: 0;
    flex-grow: 1;
  }

  .export-item-info {
    display: flex;
    flex-wrap: wrap;
    column-gap: v.$default-thickness;
  }

  .export-item-actions {
    display: flex;
    align-items: center;
  }

  .export-item-expand-indicator {
    width: 24px;
  }
}
