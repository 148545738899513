@mixin favorites-menu-global {
  .mpms-favorites-menu {
    &__lists-menu {
      &.mat-mdc-menu-panel {
        min-width: 280px;
        max-width: none;
      }
    }
  }
}
