@use '@angular/material' as mat;

@use 'colors' as c;
@use 'variables' as v;
@use 'typography' as t;

@mixin error-alert($theme) {
  $dark-red: c.$color-warn-500;
  $medium-red: c.$color-warn-200;
  $light-red: c.$color-warn-50;

  .error-alert {
    display: flex;
    padding: v.$default-thickness;
    margin-bottom: v.$default-thickness;

    border: 1px solid $medium-red;
    border-radius: 4px;

    background-color: $light-red;

    .error-alert-icon {
      color: $dark-red;
      margin-right: v.$default-thickness;
    }

    .error-alert-bold-text {
      @include mat.m2-typography-level(t.$mp-typography, subtitle-2);
    }

    .error-alert-message {
      margin-left: v.$default-thickness * 0.5;
    }
  }
}
