@use '@angular/material' as mat;

@use 'colors' as c;
@use 'variables' as v;
@use 'typography' as t;

@mixin mp-view($theme) {
  $title-bar-background: c.$color-background-dialog;
  $context-bar-background: c.$color-background-card;

  .mp-view-action-fab {
    position: relative;
    top: 24px;
    right: v.$default-thickness * 2;

    &--custom {
      top: 40px;
    }
  }

  .mp-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    .title-bar {
      z-index: 2;
      display: flex;
      align-items: center;
      height: v.$default-toolbar-height;
      padding-left: v.$default-thickness;
      background: $title-bar-background;

      @include mat.m2-typography-level(t.$mp-typography, headline-6);

      .title {
        flex: 1;
      }
    }

    .detail-page {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      padding: v.$default-thickness;

      .detail-page-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: v.$default-thickness;

        .page-column {
          .mat-mdc-form-field {
            width: 50%;
            min-width: 400px;
            margin-right: v.$default-thickness * 2;
            margin-bottom: v.$default-thickness;

            .mat-mdc-form-field-hint {
              order: 2;
            }
          }
        }

        .mat-mdc-tab-group {
          margin-top: v.$default-thickness * 2;
        }
      }
    }

    .detail-page-context-bar {
      display: flex;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      bottom: 0;
      background: $context-bar-background;
      box-shadow: v.$default-box-shadow;
    }

    &:not(.mp-view--custom) {
      overflow: hidden;

      .detail-page {
        overflow: hidden;

        .detail-page-content {
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}
