@use 'material/custom-styles/snack' as snack-custom-styles;

@use 'colors' as c;

@mixin mp-toast-global {
  .mp-toast {
    @include snack-custom-styles.snack-text-color(#fff);

    &--warning,
    &--success {
      @include snack-custom-styles.snack-button-text-color(#fff);
    }

    &--warning {
      @include snack-custom-styles.snack-background-color(c.$color-state-red-500);
    }

    &--success {
      @include snack-custom-styles.snack-background-color(c.$color-state-green-500);
    }
  }
}
