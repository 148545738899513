@use 'colors' as c;
@use 'variables' as v;

@mixin change-password-input-global {
  .mp-change-password-input {
    &__requirements {
      // Additional negative offset which skips the subscript to ensure that the overlay is just below the form-field underline
      margin-top: -(v.$form-field-subscript-height);
      width: 100%;

      box-shadow: v.$default-box-shadow;
      background-color: white;

      .requirement {
        cursor: default;

        &__row {
          display: flex;
          align-items: center;

          color: c.$color-state-red-500;

          &-icon {
            flex: 0 auto;
          }

          &-label {
            flex: 1;
          }

          &--fulfilled {
            color: c.$color-state-green-500;
          }
        }
      }
    }
  }
}
