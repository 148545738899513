@use 'sass:map';

@use 'variables' as v;

/// BREAKPOINT MIXIN
///
/// Usage:
/// @include breakpoint(xl) {
///   background: purple;
/// }

@mixin breakpoint($name) {
  @if not map.has-key(v.$breakpoints, $name) {
    @warn "Invalid breakpoint `#{$name}`.";
  } @else {
    @media (min-width: map.get(v.$breakpoints, $name)) {
      @content;
    }
  }
}

/// Icon size mixin
/// Sets all of the necessary css properties to change icon size
@mixin icon-size($icon-size) {
  font-size: $icon-size;
  line-height: $icon-size;
  width: $icon-size;
  height: $icon-size;
}

@mixin mp-page-aside-padding {
  padding: 1.5 * v.$default-thickness 1.5 * v.$default-thickness v.$default-thickness 1.5 * v.$default-thickness;

  @include breakpoint(lg) {
    padding-block: 2 * v.$default-thickness;
  }
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
