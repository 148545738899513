@use './loading-spinner';

@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin mp-page-scrollable {
  overflow: hidden;

  .mp-page__main {
    height: 100%;

    .mp-page__content {
      overflow-y: auto;
    }

    .mp-page__aside {
      overflow-y: auto;
    }
  }
}

@mixin mp-page($theme) {
  $loader-background: c.$color-app-loader-background;
  $loader-foreground: c.$color-app-loader-foreground;

  $color-accent: c.$color-accent-500;
  $color-light-gray: c.$color-gray-50;

  body {
    margin: 0;
    height: 100vh;
    background: $loader-background;

    @media print {
      height: 100%;
      background: transparent;
    }
  }

  .app-loader {
    height: 66%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $loader-foreground;

    .spinner {
      color: $loader-foreground;

      @include loading-spinner.spinner-common();

      > div {
        background: $loader-foreground;
      }
    }
  }

  .mat-drawer-side.mat-sidenav {
    border-right-color: c.$color-gray-100;
  }

  .mat-snack-bar-container {
    &.mp-notification {
      width: unset !important;
      min-width: unset !important;
    }
  }

  .icon-tooltip {
    background: c.$color-layout-500;
  }

  // TODO Delete
  /* Match Tag */
  .mat-chip.mat-standard-chip {
    background: $color-light-gray;
  }

  mat-card.mat-mdc-card:not([class*='mat-elevation-z']),
  mat-expansion-panel.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: v.$card-box-shadow;
  }

  @media print {
    mat-card.mat-mdc-card:not([class*='mat-elevation-z']) {
      padding: 0 0 v.$default-thickness * 2 0;
      border-bottom: 1px v.$default-shadow-color solid;
      box-shadow: none;
    }
  }

  // MP-PAGE
  .mp-page {
    display: block;
    width: 100%;
    position: relative;
    height: 100%;

    .mp-page__head {
      box-sizing: border-box;
    }

    .mp-page__main {
      box-sizing: border-box;
      display: block;
      height: auto;
      min-height: 100%;

      // Fixed layout - regardless of the resolution the aside part will be always on the left side
      &--fixed-layout {
        display: flex;
        flex-direction: row;
      }

      @include m.breakpoint(lg) {
        display: flex;
        flex-direction: row;
      }

      .mp-page__aside {
        box-sizing: border-box;
        flex: 1 1 100%;
        min-width: 0;

        @include m.breakpoint(lg) {
          flex: 0 0 v.$app-page-aside-width;
        }
      }

      .mp-page__aside-filters {
        flex-shrink: 0;
        flex-grow: 0;
      }

      .mp-page__content {
        box-sizing: border-box;
        flex: 1 1 100%;
        min-width: 0;

        @include m.breakpoint(lg) {
          flex: 1 1 auto;
        }
      }
    }

    // MP-PAGE Padding
    .mp-page__head.mp-page--padding {
      padding: 0 v.$default-thickness * 1.5;
      @include m.breakpoint(lg) {
        padding: 0 v.$default-thickness * 2;
      }
    }

    .mp-page__aside.mp-page--padding {
      @include m.mp-page-aside-padding;
    }

    .mp-page__content.mp-page--padding {
      padding: v.$default-thickness * 1.5;
      @include m.breakpoint(lg) {
        padding: v.$default-thickness * 2 v.$default-thickness * 2 v.$default-thickness * 2 v.$default-thickness * 2;
      }
    }

    // MP-PAGE Centered
    &.mp-page--centered,
    .mp-page__content-inner--centered {
      max-width: 1420px;
      margin: 0 auto;
    }

    // MP-PAGE Scrollable
    &.mp-page--scrollable-wide-view {
      @include m.breakpoint(lg) {
        @include mp-page-scrollable;
      }
    }

    &.mp-page--scrollable {
      @include mp-page-scrollable;
    }

    // MP-PAGE Border
    &.mp-page--border {
      .mp-page__content {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
        // Remove box shadow from the right side of the content
        clip-path: inset(0 0 0 -6px);
      }
    }
  }

  // MP-PAGE Storybook demo
  .sb-show-main {
    .mp-page,
    .mp-page__aside,
    .mp-page__content {
      border: 1px #ccc dashed;
    }
  }
}
