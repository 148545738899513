@use 'material/custom-styles/slide-toggle' as slide-toggle-custom-styles;

@use 'colors' as c;
@use 'mixins' as m;

@mixin platformcatalog-panel-common {
  $btn-border-color: c.$color-gray-100;
  $btn-label-color: c.$color-gray-600;

  .actions-slide-toggle {
    @include slide-toggle-custom-styles.slide-toggle-hidden-ripple;

    &__label {
      font-weight: bold;
      font-size: 13px;
    }
  }

  .actions-slide-toggle-button:not(:disabled) {
    .actions-slide-toggle {
      &__label {
        color: $btn-label-color;
      }
    }
  }

  .actions-button {
    height: 30px;
    line-height: 28px;
    padding: 0 8px;
    min-width: 0;
    margin-left: 8px;
    flex: none;

    &:disabled:not(.mat-mdc-outlined-button) {
      color: #fff;
    }

    &.mat-mdc-outlined-button {
      border-color: $btn-border-color;
      &:not(:disabled) {
        color: $btn-label-color;
      }
    }

    mat-icon {
      @include m.icon-size(20px);
      margin: 0;
    }
  }

  .action-button {
    &:not(:disabled) {
      color: $btn-label-color;
    }
  }
}
