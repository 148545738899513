@use 'material/custom-styles/dialog' as dialog-custom-styles;

@use 'colors' as c;

@mixin zendesk-support-form-dialog-global {
  .mp-zendesk-support-form-dialog {
    &-container {
      .mat-mdc-dialog-container {
        @include dialog-custom-styles.dialog-container-background-color(c.$color-background-background);

        max-width: 80vw;
        width: 600px;
        border-radius: 12px;
        position: relative;
      }
    }

    &__attachments-info-tooltip {
      max-width: 300px;
      white-space: pre-line;
    }
  }
}
