@mixin chip-item-font-size($font-size) {
  --mdc-chip-label-text-size: #{$font-size};
}

@mixin chip-item-height($height) {
  --mdc-chip-container-height: #{$height};
}

@mixin chip-item-color($color) {
  --mdc-chip-label-text-color: #{$color};
}

@mixin chip-item-background($color) {
  --mdc-chip-elevated-container-color: #{$color};
}
