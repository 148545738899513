@use 'colors' as c;
@use 'variables' as v;

@mixin mp-alert($theme) {
  $dark-red: c.$color-warn-500;
  $medium-red: c.$color-warn-200;
  $light-red: c.$color-warn-50;

  .mp-alert {
    .headline {
      display: flex;

      & > [mpAlertTitle] {
        flex: 1;
      }
    }

    .alert-info,
    .alert-warning,
    .alert-danger {
      padding: v.$default-thickness;
      margin-bottom: v.$default-thickness;

      border: 1px solid;
      border-radius: v.$default-border-radius;

      mat-icon {
        margin-right: v.$default-thickness;
        margin-bottom: v.$default-thickness;
      }
    }

    .alert-info {
      border-color: c.$color-layout-500;

      mat-icon {
        color: c.$color-layout-500;
      }
    }

    .alert-warning {
      border-color: c.$color-gold-200;
      background-color: c.$color-gold-50;

      mat-icon {
        color: c.$color-gold-400;
      }
    }

    .alert-danger {
      border-color: $medium-red;
      background-color: $light-red;

      mat-icon {
        color: $dark-red;
      }
    }
  }
}
