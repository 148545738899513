@use 'colors' as c;
@use 'mixins' as m;

@mixin rich-text-editor-toolbar($theme) {
  $color-menu-item-background: c.$color-layout-50;
  $color-menu-item-text: c.$color-layout-500;

  .mp-rich-text-editor-toolbar {
    .toolbar-tools-group {
      button.toolbar-tools-group__tool,
      .ql-picker-label {
        &.ql-active,
        &:hover,
        &:focus {
          color: $color-menu-item-text;
          background-color: $color-menu-item-background;
          border-radius: 3px;

          .ql-fill {
            fill: $color-menu-item-text;
          }

          .ql-stroke {
            stroke: $color-menu-item-text;
          }
        }
      }
    }
  }
}

:host {
  .toolbar-tools-group {
    &:not(.toolbar-tools-group--optional) {
      display: inline-flex;
      gap: 1px;
    }

    &--optional {
      display: none;

      @include m.breakpoint(lg) {
        display: inline-flex;
      }
    }
  }
}
