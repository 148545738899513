@use 'colors' as c;

@mixin list-default-styles {
  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-weight: normal;

    --mdc-list-list-item-hover-state-layer-opacity: 0;

    &.no-padding {
      padding: 0;
    }

    .mdc-list-item {
      &:hover {
        --mdc-list-list-item-container-color: #{c.$color-background-hover};
      }
    }
  }
}
