@use '@angular/material' as mat;

@use 'colors' as c;
@use 'variables' as v;
@use 'typography' as t;

@mixin mp-nav-item($theme) {
  $item-normal-foreground: c.$color-layout-500;
  $item-active-background: c.$color-primary-50;
  $item-active-group-background: c.$color-layout-100;
  $item-active-group-bar-background: c.$color-layout-200;
  $item-expanded-background: c.$color-layout-100;
  $item-disabled-foreground: c.$color-foreground-disabled-button;

  $bar-color-expanded: c.$color-layout-300;
  $bar-color-active: c.$color-primary-400;

  .mp-nav-item {
    display: block;
    @include mat.m2-typography-level(t.$mp-typography, subtitle-1);

    position: relative;

    .link {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      flex: 1;
      height: v.$default-toolbar-height;
      transition: v.$default-transition;
      color: $item-normal-foreground;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background: $item-active-group-bar-background;
        border-radius: 0;
        transition: width 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        transition: v.$default-transition;
        color: $item-normal-foreground;
        background: $item-active-background;

        &:before {
          width: 3px;
          background-color: $bar-color-active;
        }
      }

      &:visited {
        color: $item-normal-foreground;
        transition: v.$default-transition;
      }

      &:focus {
        color: $item-normal-foreground;
        transition: v.$default-transition;
      }

      &.active {
        color: $item-normal-foreground;
        background: $item-active-background;
        transition: v.$default-transition;
        @include mat.m2-typography-level(t.$mp-typography, body-1);

        &:before {
          width: 3px;
          background-color: $bar-color-active;
        }
      }

      &.expanded {
        color: $item-normal-foreground;
        background: $item-active-group-background;
        transition: v.$default-transition;
        @include mat.m2-typography-level(t.$mp-typography, body-1);

        &:before {
          width: 3px;
          background-color: $bar-color-expanded;
        }
      }

      .child-item-line {
        border-bottom: 2px solid c.$color-gray-200;
        width: v.$default-thickness;
      }

      .icon {
        padding-inline: v.$navigation-item-padding;
      }
    }

    .group-label {
      display: none;
    }

    .group-content {
      background: $item-expanded-background;

      mp-nav-item a {
        &.active:before {
          width: 3px;
          background: $bar-color-active;
        }
      }
    }
  }
}
