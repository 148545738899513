@use 'colors' as c;
@use 'variables' as v;

@mixin supplier-autocomplete-input-global {
  .mpsm-supplier-autocomplete-input {
    &__autocomplete-panel {
      .supplier-item {
        cursor: pointer;
        line-height: normal;

        display: flex;
        align-items: center;
        height: 100%;
        gap: 10px;
        margin: v.$default-thickness 0 v.$default-thickness 0.5 * v.$default-thickness;

        .supplier-item-icon {
          width: 36px;
          height: 36px;
        }

        .supplier-item-text {
          color: c.$color-layout-500;

          flex: 1;
          white-space: normal;

          h4 {
            color: inherit;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;

            margin: 0.5 * v.$default-thickness 0 0;
            max-width: 100%;
          }

          span {
            color: c.$color-gray-600;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;

            display: block;
            max-width: 100%;
            margin: 0 0 4px 0;
          }

          h4 + span {
            margin: 4px 0;
          }
        }

        &--active {
          .supplier-item-text {
            color: inherit;
          }
        }
      }
    }
  }
}
