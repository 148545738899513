@use 'material/custom-styles/dialog' as dialog-custom-styles;

@mixin info-dialog-global {
  .mp-info-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(white);
      max-width: 80vw;
    }
  }
}
