@use 'colors/utilities' as color-utils;

@use 'colors' as c;
@use 'mixins' as m;

@mixin option-default-styles {
  mat-option.mat-mdc-option {
    height: fit-content;
    min-height: 48px;
    box-sizing: border-box;

    --mat-option-label-text-color: #{c.$option-color};
    --mat-option-selected-state-label-text-color: #{c.$option-active-color};

    font-size: inherit;
    line-height: inherit;

    --mat-option-label-text-weight: normal;

    $selected-option-background-color: color-utils.add-opacity-to-color-variable(c.$color-background-hover, 0.12);
    --mat-option-hover-state-layer-color: #{c.$color-background-hover};
    --mat-option-focus-state-layer-color: #{$selected-option-background-color};
    --mat-option-selected-state-layer-color: #{$selected-option-background-color};

    // Handling truncation as new option wraps items instead of truncating them
    @include m.text-truncate;

    &.mat-mdc-option-active,
    &:focus {
      color: c.$option-active-color;

      // Keep normal hover behavior for active/focused options which are not selected
      &:hover {
        --mat-option-focus-state-layer-color: var(--mat-option-hover-state-layer-color);
      }
    }

    &:nth-child(even) {
      background-color: c.$option-background-color;
    }

    &.mdc-list-item--disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    .mdc-list-item__primary-text {
      flex-grow: 1;

      // Handling truncation as new option wraps items instead of truncating them
      @include m.text-truncate;
    }

    .mat-icon {
      // Cancel out the annoying forced margin on every icon in the option content
      margin-right: 0;
    }
  }
}
