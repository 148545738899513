@mixin catalog-supplier-selector-global {
  .mpsm-catalog-supplier-selector {
    .typ-icon {
      mp-catalog-type-icon {
        mat-icon {
          height: 24px;
          width: 24px;
          font-size: 20px;
        }
      }
    }
  }
}
