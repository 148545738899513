@use '../mixins' as m;
@use '../variables' as v;

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 v.$default-thickness * -1;

  & > .column {
    flex: 1;
    padding: v.$default-thickness;
  }
}

// container class is good for a padding outside of the content
.container {
  padding: 0 v.$default-thickness;

  @include m.breakpoint(md) {
    padding: 0 v.$default-thickness * 2;
  }
}

/// Utility Classes for recurring cases
/// Usage:
/// eg: .flex .xs:flex .sm:flex .md:flex .lg:flex .xl:flex

$displayValues: (
  hidden: none,
  flex: flex,
  block: block,
  inline: inline,
  inline-block: inline-block,
);

@each $class, $displayValue in $displayValues {
  .#{$class} {
    display: $displayValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        display: #{$displayValue};
      }
    }
  }
}

/// sometimes you need this to be important
@each $key, $value in v.$breakpoints {
  @include m.breakpoint($key) {
    .#{$key}\:\!hidden {
      display: none !important;
    }
  }
}

$alignItemsValues: (
  items-start: flex-start,
  items-end: flex-end,
  items-center: center,
  items-baseline: baseline,
  items-stretch: stretch,
);

@each $class, $alignItemsValue in $alignItemsValues {
  .#{$class} {
    align-items: $alignItemsValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        align-items: #{$alignItemsValue};
      }
    }
  }
}

$justifyContentValues: (
  justify-center: center,
  justify-between: space-between,
  justify-around: space-around,
  justify-start: flex-start,
  justify-end: flex-end,
);

@each $class, $justifyContentValue in $justifyContentValues {
  .#{$class} {
    justify-content: $justifyContentValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        justify-content: #{$justifyContentValue};
      }
    }
  }
}

$flexWrapValues: (
  flex-wrap: wrap,
  flex-nowrap: nowrap,
);

@each $class, $flexWrapValue in $flexWrapValues {
  .#{$class} {
    flex-wrap: $flexWrapValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        flex-wrap: #{$flexWrapValue};
      }
    }
  }
}

$flexDirectionValues: (
  flex-col: column,
  flex-row: row,
);

@each $class, $flexDirectionValue in $flexDirectionValues {
  .#{$class} {
    flex-direction: $flexDirectionValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        flex-direction: #{$flexDirectionValue};
      }
    }
  }
}

.flex-1 {
  flex: 1 1 0;
}
.flex-auto {
  flex: 1 1 auto;
}

$positionValues: (
  fixed: fixed,
  absolute: absolute,
  relative: relative,
  sticky: sticky,
);

@each $class, $positionValue in $positionValues {
  .#{$class} {
    position: $positionValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        position: #{$positionValue};
      }
    }
  }
}

$textAlignValues: (
  text-left: left,
  text-center: center,
  text-right: right,
);

@each $class, $textAlignValue in $textAlignValues {
  .#{$class} {
    text-align: $textAlignValue;
  }

  @each $key, $value in v.$breakpoints {
    @include m.breakpoint($key) {
      .#{$key}\:#{$class} {
        text-align: #{$textAlignValue};
      }
    }
  }
}

// Font Size Variants

.text-xs {
  font-size: 13px;
}
.text-base {
  font-size: 16px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 24px;
}
.text-2xl {
  font-size: 36px;
}

.font-regular {
  font-weight: 300;
}
.font-medium {
  font-weight: 400;
}
.font-bold {
  font-weight: 700;
}

.font-mono {
  font-family: v.$mono-font-family;
}

.truncate {
  @include m.text-truncate;
}

.clear {
  &::before,
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}
