@use 'colors' as c;
@use 'variables' as v;

@mixin form-field-default-styles {
  mat-form-field {
    $form-field-left-padding: var(--mat-form-field-left-padding, v.$form-field-default-side-padding);
    $form-field-right-padding: var(--mat-form-field-right-padding, v.$form-field-default-side-padding);

    $form-field-text-color: var(--mat-form-field-text-color, c.$form-field-text-color);
    $form-field-caret-color: var(--mat-form-field-caret-color, c.$form-field-caret-color);

    $form-field-placeholder-color: var(--mat-form-field-placeholder-color, c.$input-placeholder-color);
    $form-field-placeholder-font-weight: var(--mat-form-field-placeholder-font-weight, normal);

    $form-field-outlined-default-border-radius: var(--mdc-outlined-text-field-container-shape);

    &.mat-mdc-form-field {
      width: 100%;

      --mdc-filled-text-field-label-text-size: 16px;
      --mdc-outlined-text-field-label-text-size: 16px;

      --mdc-filled-text-field-label-text-weight: normal;
      --mdc-outlined-text-field-label-text-weight: normal;

      --mat-form-field-container-text-size: 16px;
      --mat-form-field-container-text-weight: normal;

      --mdc-filled-text-field-label-text-color: #{c.$form-field-label-color};
      --mdc-outlined-text-field-label-text-color: #{c.$form-field-label-color};

      --mdc-filled-text-field-input-text-color: #{$form-field-text-color};
      --mdc-outlined-text-field-input-text-color: #{$form-field-text-color};

      --mdc-filled-text-field-caret-color: #{$form-field-caret-color};
      --mdc-outlined-text-field-caret-color: #{$form-field-caret-color};

      --mdc-filled-text-field-hover-label-text-color: #{c.$form-field-label-color};
      --mdc-outlined-text-field-hover-label-text-color: #{c.$form-field-label-color};

      --mdc-filled-text-field-input-text-placeholder-color: #{$form-field-placeholder-color};
      --mdc-outlined-text-field-input-text-placeholder-color: #{$form-field-placeholder-color};

      --mdc-filled-text-field-active-indicator-color: #{c.$form-field-underline-color};
      --mdc-filled-text-field-hover-active-indicator-color: #{c.$form-field-underline-color};

      --mdc-filled-text-field-focus-active-indicator-color: #{c.$form-field-underline-ripple-color};

      --mdc-outlined-text-field-outline-color: #{c.$color-layout-500};
      --mdc-outlined-text-field-hover-outline-color: #{c.$color-layout-500};

      --mdc-filled-text-field-container-color: var(--mat-form-field-container-background-color, rgba(0, 0, 0, 0.04));

      --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
      --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);

      .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
        background-color: var(--mat-form-field-container-background-color, transparent);
      }

      .mdc-text-field--outlined {
        $wrapper-leading-border-radius: var(--mat-form-field-outlined-leading-border-radius, 4px);
        border-top-left-radius: $wrapper-leading-border-radius;
        border-bottom-left-radius: $wrapper-leading-border-radius;

        .mdc-notched-outline__leading {
          $leading-border-radius: var(
            --mat-form-field-outlined-leading-border-radius,
            $form-field-outlined-default-border-radius
          );

          border-top-left-radius: $leading-border-radius;
          border-bottom-left-radius: $leading-border-radius;
        }

        .mdc-notched-outline__trailing {
          $trailing-border-radius: var(
            --mat-form-field-outlined-trailing-border-radius,
            $form-field-outlined-default-border-radius
          );

          border-top-right-radius: $trailing-border-radius;
          border-bottom-right-radius: $trailing-border-radius;
        }
      }

      .mat-mdc-form-field-focus-overlay {
        // Disable hover and ripple effect
        display: none;
      }

      .mat-mdc-form-field-infix {
        min-height: var(--mat-form-field-infix-min-height, 56px);
      }

      .mdc-text-field:not(.mdc-text-field--outlined) {
        .mat-mdc-form-field-infix {
          padding-top: var(--mat-form-field-padding-top, 24px);
          padding-bottom: var(--mat-form-field-padding-bottom, 8px);
        }
      }

      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea),
      .mdc-text-field--outlined {
        .mat-mdc-form-field-infix {
          padding-top: var(--mat-form-field-padding-top, 16px);
          padding-bottom: var(--mat-form-field-padding-bottom, 16px);
        }
      }

      .mat-mdc-text-field-wrapper {
        // Needed for chip-input autocomplete as selected chip slightly overflows the form field
        overflow: visible;
      }

      &:hover {
        .mdc-text-field:not(.mdc-text-field--disabled) {
          .mdc-line-ripple {
            --mdc-filled-text-field-active-indicator-height: 2px;
          }

          .mdc-notched-outline {
            --mdc-outlined-text-field-outline-width: var(--mat-form-field-outlined-hover-border-width, 1px);
          }
        }
      }
    }

    .mdc-text-field,
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding-block: 0;
      padding-inline: $form-field-left-padding $form-field-right-padding;
    }

    .mdc-text-field {
      .mat-mdc-form-field-icon-prefix {
        &:empty,
        & > .mat-icon {
          padding-left: $form-field-left-padding;
        }

        & > :not(.mat-icon):first-child {
          margin-left: 4px;
        }
      }

      .mat-mdc-form-field-icon-suffix {
        &:empty,
        & > .mat-icon {
          padding-right: $form-field-right-padding;
        }

        & > :not(.mat-icon):last-child {
          margin-right: 4px;
        }
      }
    }

    .mat-mdc-form-field-icon-infix,
    .mat-mdc-form-field-icon-suffix {
      flex-shrink: 0;
    }

    .mat-mdc-floating-label {
      max-width: 100%;
    }

    .mdc-line-ripple {
      display: var(--mat-form-field-underline-display, block);
    }

    .mat-mdc-input-element {
      // NOTE: It is actually applied by the form field component styles but it needs to be defined globally for cdk autosize to work correctly
      font: inherit;

      &::placeholder {
        font-weight: $form-field-placeholder-font-weight;
      }
    }

    input.mat-mdc-chip-input {
      max-width: 100%;
    }
  }

  mat-hint {
    color: c.$color-gray-600;
  }
}
