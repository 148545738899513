@use 'colors' as c;
@use 'variables' as v;

@mixin industriekatalog-detail($theme) {
  $border-color: c.$color-layout-50;

  $font-label: 300 #{14px}/#{20px} 'Roboto';
  $font-val: 700 #{16px}/#{20px} 'Roboto';

  $color-text: c.$color-gray-700;
  $color-gray: c.$color-gray-600;
  $color-gray-dark: c.$color-gray-900;

  $item-foreground: c.$color-layout-500;

  $box-shadow:
    0 0 1px 1px rgb(0 0 0 / 5%),
    0 1px 1px 0px rgb(0 0 0 / 10%);

  .mp-industriekatalog-detail {
    .katalog-head {
      display: flex;

      .katalog-head-main {
        flex: 3;
        display: flex;
        flex-direction: column;
        padding-right: v.$default-thickness;

        .action-row__selectors,
        .data-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .action-row__selectors {
          mpsm-catalog-supplier-selector {
            padding: 12px 0 0 0;
            margin: 0 32px 16px 0;

            .typ-icon h2.katalog-lieferant {
              font-size: 28px;
              line-height: 32px;
              color: $item-foreground;
            }

            mp-catalog-type-icon mat-icon {
              color: $item-foreground;
            }

            .mp-catalog-type-icon {
              height: 44px;
              mat-icon {
                font-size: 44px;
                line-height: 44px;
                height: 44px;
                width: 44px;
              }
            }
          }

          .lieferant-select,
          .katalogtyp-select {
            flex: 1 1 auto;
          }

          .katalogtyp-select {
            padding: 0;
            margin: 0 32px 0 0;
            max-width: 320px;
          }
        }

        .data-row {
          padding-top: v.$default-thickness;

          .item {
            margin-right: 2 * v.$default-thickness;

            .item__label {
              color: $color-gray;
            }

            .item__value {
              padding-left: 0.5 * v.$default-thickness;
              font-weight: bold;
              color: $color-gray-dark;
            }
          }
        }

        .catalog-task-wrapper {
          display: flex;
          gap: 1rem;
        }
      }

      .katalog-head-aside {
        flex: 1;
        display: flex;
        flex-direction: column;

        border: 1px solid $border-color;
        border-radius: 4px;
        padding: v.$default-thickness * 3 v.$default-thickness * 1.5 v.$default-thickness * 1.5;
        background-color: #fff;
        box-shadow: $box-shadow;

        .top-row {
          flex-direction: row;
          display: flex;

          .head-block {
            flex: 1;
            margin-bottom: 2 * v.$default-thickness;

            .head-block__count {
              font-size: 32px;
              line-height: 34px;
              color: $color-gray-dark;
              margin-bottom: 4px;
              padding: 0;
            }

            mp-catalog-covin-badge {
              margin: 0 0 4px 0;

              .val {
                font-size: 22px;
                line-height: 34px;
                margin-bottom: 4px;
                padding: 0;
                color: $color-gray;
              }
            }

            .head-block__label,
            .head-block__label__covin {
              font-size: 14px;
              letter-spacing: 0.025em;
              text-transform: uppercase;
              color: $color-gray;
            }

            .head-block__label__covin {
              text-align: end;
            }
          }
        }

        .datatable {
          display: flex;
          flex-direction: column;

          .meta-item {
            flex: 0 0 50%;
            display: flex;
            justify-content: space-between;

            padding: v.$default-thickness * 0.25 0;
            margin-bottom: v.$default-thickness * 0.25;
            border-bottom: 1px #ececec solid;

            color: $color-text;

            .meta-item__label {
              font: $font-label;
              display: block;
            }

            .meta-item__value {
              font: $font-val;
              display: inline-block;
              vertical-align: middle;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
