@use 'material/custom-styles/dialog' as dialog-custom-styles;

@use 'colors' as c;

@mixin review-conditions-dialog-global {
  .mpctm-review-conditions-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(c.$color-background-background);
      min-width: 60vw;
      max-width: 80vw;
    }
  }
}
