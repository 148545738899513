@use 'mixins' as m;

@mixin icon-button-size($button-size, $icon-size) {
  --mdc-icon-button-state-layer-size: #{$button-size};

  // Required for svg icons as it applies the size to the svg element directly
  --mdc-icon-button-icon-size: #{$icon-size};

  .mat-icon {
    @include m.icon-size($icon-size);
  }
}

@mixin button-hidden-persistent-ripple {
  --mat-button-persistent-ripple-display: none;
}
