@use 'colors' as c;
@use 'variables' as v;

@use '../content-section.scss';

@mixin packungseinheiten-content-section {
  .mpcm-packungseinheiten-content-section {
    background-color: white;
    display: block;

    @include content-section.content-section;

    .artikel-section-value {
      span + span {
        margin-left: 0.25 * v.$default-thickness;
      }

      .val__unit {
        font-size: 18px;
        line-height: 18px;
      }

      .val__factor {
        vertical-align: top;
        line-height: 18px;
      }

      .val__suffix {
        vertical-align: top;
        line-height: 18px;
        font-weight: 300;
        margin-left: 2px;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .red {
      color: c.$color-state-red-500;
    }

    .green {
      color: c.$color-state-green-500;
    }

    .visibility-hidden {
      visibility: hidden;
    }
  }
}
