@use 'colors' as c;
@use 'variables' as v;

@mixin table-default-styles {
  $table-header-row-border: 2px c.$table-border-color solid;
  $table-row-border: 1px c.$table-border-color solid;

  $table-header-cell-color: c.$color-gray-400;
  $table-cell-color: c.$color-gray-600;

  $table-even-row-bg-color: c.$color-layout-10;

  table.mat-mdc-table {
    width: 100%;
  }

  table.mat-mdc-table {
    table-layout: auto;
  }

  // Either mat-mdc-table or normal table with mat-mdc-table directive
  table,
  mat-table {
    &.mat-mdc-table {
      --mat-table-header-headline-size: 13px;
      --mat-table-row-item-outline-width: 0px;

      --mat-table-header-headline-color: #{c.$color-gray-400};
      --mat-table-row-item-label-text-color: #{c.$color-gray-600};

      &.no-bottom-border {
        mat-row.mat-mdc-row {
          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      &.top-border {
        mat-header-row.mat-mdc-header-row,
        th.mat-mdc-header-cell {
          border-top: $table-header-row-border;
        }
      }
    }

    mat-row.mat-mdc-row,
    mat-header-row.mat-mdc-header-row,
    mat-footer-row.mat-mdc-footer-row,
    th.mat-mdc-header-cell,
    td.mat-mdc-cell,
    td.mat-mdc-footer-cell {
      border-bottom-color: c.$table-border-color;
    }

    mat-row.mat-mdc-row {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    mat-header-row.mat-mdc-header-row,
    th.mat-mdc-header-cell {
      border-bottom-width: 2px;
      border-bottom-style: solid;
    }
  }

  div.mat-mdc-no-data-row {
    color: $table-cell-color;
    background-color: $table-even-row-bg-color;
    border-bottom: $table-row-border;
  }

  div.mat-mdc-no-data-row {
    text-align: center;
    padding-block: v.$default-thickness;
  }

  mat-header-row.mat-mdc-header-row {
    min-height: var(--table-header-row-min-height, v.$table-header-row-min-height);
  }

  table.mat-mdc-table tr.mat-mdc-header-row {
    height: var(--table-header-row-min-height, v.$table-header-row-min-height);
  }

  mat-header-cell.mat-mdc-header-cell,
  mat-cell.mat-mdc-cell,
  table.mat-mdc-table th.mat-mdc-header-cell,
  table.mat-mdc-table td.mat-mdc-cell {
    padding: v.$table-cell-padding;

    &:first-of-type {
      padding-left: var(--table-first-cell-left-padding, 2 * v.$table-cell-padding);
    }

    &:last-of-type {
      padding-right: var(--table-last-cell-right-padding, 2 * v.$table-cell-padding);
    }
  }

  tr.mat-mdc-row,
  mat-row.mat-mdc-row {
    &:nth-of-type(even) {
      background-color: $table-even-row-bg-color;
    }

    &:hover {
      background: c.$color-background-hover;
      transition: v.$default-transition;
    }
  }

  .expandable {
    tr.mat-mdc-row,
    mat-row.mat-mdc-row {
      background: inherit;

      // We skip expandable rows and start with second non-expandable row
      &:nth-of-type(4n + 3) {
        background-color: $table-even-row-bg-color;
      }

      &:hover {
        background: c.$color-background-hover;
        transition: v.$default-transition;
      }

      &:not(.expansion-row) {
        td.mat-mdc-cell,
        mat-cell.mat-mdc-cell {
          border-bottom: none;
        }
      }

      &.expansion-row {
        height: unset;
        min-height: unset;
        background: inherit;

        td.mat-mdc-cell,
        mat-cell.mat-mdc-cell {
          padding: 0;
        }
      }
    }
  }

  mat-row.mat-mdc-row {
    min-height: var(--table-row-min-height, v.$table-row-min-height);
  }

  table.mat-mdc-table tr.mat-mdc-row {
    height: var(--table-row-min-height, v.$table-row-min-height);
  }

  mat-footer-row.mat-mdc-footer-row {
    height: unset;
    min-height: var(--table-footer-row-min-height, var(--mat-table-footer-container-height));
  }
}
