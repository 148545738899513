@use 'colors' as c;
@use 'mixins' as m;

@mixin menu-default-styles {
  .mat-mdc-menu-panel {
    --mat-menu-item-label-text-size: 16px;
    --mat-menu-item-label-text-weight: 400;

    --mat-menu-item-icon-color: #{c.$color-foreground-icon};

    --mat-menu-item-hover-state-layer-color: #{c.$color-background-hover};
    --mat-menu-item-focus-state-layer-color: #{c.$color-background-hover};

    &.no-padding {
      .mat-mdc-menu-content:not(:empty) {
        padding-block: 0;
      }
    }
  }

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      // Handling truncation as new menu wraps items instead of truncating them
      span.mat-mdc-menu-item-text {
        @include m.text-truncate;
      }
    }
  }

  button.mat-mdc-menu-item {
    height: 64px;

    &:nth-child(even) {
      background-color: c.$color-layout-10;
    }
  }
}
