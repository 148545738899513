@mixin copy-to-clipboard-button-global {
  .mp-copy-to-clipboard-button {
    .copy-to-clipboard-button {
      // Program focused persistent ripple (which remains on a button after click, due to clipboard being used)
      &.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before {
        opacity: 0;
      }
    }
  }
}
