@use 'colors' as c;

@mixin chips-default-styles {
  .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
    max-width: 100%;
  }

  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #{c.$color-gray-50};

    --mdc-chip-label-text-weight: bold;

    // Override for truncating the chips as in MDC they overflow the container
    span {
      &.mdc-evolution-chip__cell--primary,
      &.mdc-evolution-chip__action--primary,
      &.mat-mdc-chip-action-label {
        overflow: hidden;
      }
    }
  }
}
