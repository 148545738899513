@mixin radio-unselected-outer-circle-color($color) {
  --mdc-radio-unselected-icon-color: #{$color};
  --mdc-radio-unselected-hover-icon-color: #{$color};
  --mdc-radio-unselected-focus-icon-color: #{$color};
  --mdc-radio-unselected-pressed-icon-color: #{$color};
}

@mixin radio-click-box-padding($padding-top, $padding-right: 0px, $padding-bottom: 0px, $padding-left: 0px) {
  --mat-radio-click-box-top-padding: #{$padding-top};
  --mat-radio-click-box-right-padding: #{$padding-right};
  --mat-radio-click-box-bottom-padding: #{$padding-bottom};
  --mat-radio-click-box-left-padding: #{$padding-left};
}
