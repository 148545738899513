@use 'colors' as c;
@use 'variables' as v;

@mixin news-item-edit($theme) {
  $color-gray: c.$color-gray-500;
  $color-lightgray: c.$color-gray-100;

  .mp-news-item-edit {
    .news-edit-header {
      &__title {
        color: $color-gray;
        font-weight: bold;
      }
    }

    .mat-divider.news-edit__divider {
      border-top-color: $color-lightgray;
    }
  }
}

:host {
  .news-edit {
    padding: v.$default-thickness;

    &__divider {
      margin: v.$default-thickness 0;
      position: relative;
    }
  }

  .news-edit-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.5 * v.$default-thickness;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 0;
      flex-grow: 1;

      &-icon {
        min-width: 24px;
      }

      &-content {
        margin-left: 0.5 * v.$default-thickness;
        width: 0;
        flex-grow: 1;
      }
    }
  }

  .description-form-field {
    margin-block: 0.5 * v.$default-thickness;
    // Padding set this way to match the padding from the mat-form-field
    padding-bottom: v.$form-field-subscript-height;
    position: relative;

    &__error {
      position: absolute;
      bottom: 0;
      margin-left: 1em;
      font-size: 75%;
    }
  }
}
