@use 'variables' as v;
@use '../article-number-common';

@mixin clinic-article-number-theme($theme) {
  .mpcm-clinic-article-number {
    font-weight: bold;

    .article-number {
      color: currentColor;

      &__icon {
        svg {
          path {
            fill: currentColor;
          }
        }
      }
    }
  }
}

:host {
  @include article-number-common.article-number-common;
}
