@use '@angular/material' as mat;

@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin radio-default-styles {
  @include mat.radio-density(-1);

  mat-radio-button.mat-mdc-radio-button {
    $label-left-padding: 8px;
    $radio-size: 20px;
    $click-box-top-padding: var(--mat-radio-click-box-top-padding, 0px);
    $click-box-right-padding: var(--mat-radio-click-box-right-padding, 0px);
    $click-box-bottom-padding: var(--mat-radio-click-box-bottom-padding, 0px);
    $click-box-left-padding: var(--mat-radio-click-box-left-padding, 0px);

    display: inline-block;

    &.mat-primary,
    &.mat-accent,
    &.mat-warn {
      --mdc-radio-unselected-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-radio-unselected-hover-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-radio-unselected-focus-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-radio-unselected-pressed-icon-color: #{c.$color-foreground-secondary-text};

      --mdc-radio-disabled-selected-icon-color: black;
      --mdc-radio-disabled-unselected-icon-color: black;
    }

    .mdc-form-field {
      display: grid;
      width: 100%;
      height: 100%;

      .mdc-radio,
      .mdc-label {
        // Stack radio and label on top of each other so that label covers the full click area (for easier click box manipulation)
        grid-row: 1;
        grid-column: 1;
      }

      .mdc-radio {
        margin-left: $click-box-left-padding;
      }

      .mdc-label {
        // Offset label content to the right of the radio via the padding (and add optional extra click box padding)
        $label-default-left-padding: $label-left-padding + $radio-size;
        $label-left-padding-with-extra-click-box: calc(#{$label-default-left-padding} + #{$click-box-left-padding});
        padding: $click-box-top-padding $click-box-right-padding $click-box-bottom-padding
          $label-left-padding-with-extra-click-box;

        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &:empty {
          padding: 0;

          width: calc(#{$click-box-left-padding} + #{$radio-size} + #{$click-box-right-padding});
          height: calc(#{$click-box-top-padding} + #{$radio-size} + #{$click-box-bottom-padding});
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    &.centered {
      .mdc-form-field {
        .mdc-radio {
          justify-self: center;
        }
      }
    }

    .mdc-radio {
      padding: 0;

      .mat-mdc-radio-touch-target,
      .mat-radio-ripple {
        width: var(--mdc-radio-state-layer-size);
        height: var(--mdc-radio-state-layer-size);
      }

      .mdc-radio__native-control,
      .mat-radio-ripple {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .mdc-label {
      @include m.text-truncate;

      cursor: pointer;
    }

    .mdc-radio--disabled + .mdc-label {
      cursor: default;
    }
  }
}
