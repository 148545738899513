@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin katalog-card {
  $color-green: c.$color-light-green-500;

  $color-gray: c.$color-gray-600;
  $color-gray-light: c.$color-gray-300;

  $font-small: #{14px}/#{18px} Roboto;

  .mp-katalog-card {
    margin-bottom: v.$default-thickness;
    display: block;

    mat-card {
      margin: 0;
      color: $color-gray;
      font-weight: 300;

      border-left-color: $color-gray-light;
      border-left-width: 4px;
      border-left-style: solid;

      padding: v.$default-thickness v.$default-thickness * 1.5;

      &.card-nichtZugeordnet {
        border-left-color: c.$color-state-yellow-300;
      }
      &.card-aktiv {
        border-left-color: $color-green;
      }
    }

    .card-header {
      display: flex;

      .card-header-main {
        flex: 1 1 auto;
        margin-right: v.$default-thickness !important;
        min-width: 0;

        @include m.breakpoint(md) {
          margin-right: 4 * v.$default-thickness;
        }

        .katalog-info-row {
          display: flex;
          gap: 6px;
          margin-bottom: v.$default-thickness * 0.5;
          flex-direction: column;

          @include m.breakpoint(md) {
            flex-direction: row;
            gap: 24px;
          }

          .katalog-quelle,
          .katalog-name,
          .katalog-organisationen {
            font: $font-small;
            display: flex;
            flex: 0 1 auto;
            min-width: 0;

            .value {
              margin-left: 0.25 * v.$default-thickness;
              font: $font-small;
              font-weight: bold;
              color: $color-gray;
            }
          }

          .katalog-organisationen {
            font: 24px/28px Roboto;

            .value {
              font: 700 24px/28px Roboto;
            }
          }

          .katalog-organisation-removed {
            opacity: 0.5;
            text-decoration: line-through;
          }
        }
      }

      .card-header-aside {
        flex: 0 1 auto;
        position: relative;
        text-align: right;
        min-width: 0;

        .last-import {
          font: $font-small;
          display: flex;
          flex: 0 1 auto;
          min-width: 0;

          .value {
            margin-left: 0.25 * v.$default-thickness;
            font: $font-small;
            font-weight: bold;
            color: $color-gray;
          }
        }
      }
    }

    .card-footer {
      font: $font-small;

      margin: 0;

      display: block;
      justify-content: space-between;

      @include m.breakpoint(md) {
        display: flex;
      }

      p {
        margin: 0;
        white-space: nowrap;
      }

      p.value {
        font: $font-small;
        font-weight: 700;
        margin-top: 2px;
      }

      .katalog-info {
        display: flex;
        flex: 0 0 100%;
        justify-content: space-between;

        .katalog-info-main {
          display: flex;
          align-items: center;

          .artikel-count {
            font: $font-small;
            margin: 0.5 * v.$default-thickness 2 * v.$default-thickness 0 0;
            display: flex;
            flex: 0 1 auto;
            min-width: 0;

            .value {
              margin-right: 0.25 * v.$default-thickness;
              font: $font-small;
              font-weight: bold;
              color: $color-gray;
            }
          }
        }

        .card-footer {
          display: flex;
          align-items: center;
        }

        .card-footer-tasks-info {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .card-footer-action {
          align-items: center;

          button.btn--detail,
          button.btn--save {
            height: 28px;
            line-height: 28px;
          }

          button.btn--detail {
            width: 80px;
            border-color: $color-gray-light;

            &:not(:disabled) {
              color: inherit;
            }
          }

          button.btn--save {
            background-color: $color-green;
            margin-right: v.$default-thickness;
            width: 120px;

            &:disabled {
              border-color: c.$color-gray-50;
              background-color: transparent;
            }

            mat-spinner {
              display: inline-block;
              margin-right: v.$default-thickness;
            }
          }
        }
      }
    }
  }
}
