@use '@angular/material' as mat;

@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin checkbox-default-styles {
  @include mat.checkbox-density(-1);

  mat-checkbox.mat-mdc-checkbox {
    $label-left-padding: 8px;
    $checkbox-size: 18px;
    $click-box-top-padding: var(--mat-checkbox-click-box-top-padding, 0px);
    $click-box-right-padding: var(--mat-checkbox-click-box-right-padding, 0px);
    $click-box-bottom-padding: var(--mat-checkbox-click-box-bottom-padding, 0px);
    $click-box-left-padding: var(--mat-checkbox-click-box-left-padding, 0px);

    &.mat-primary,
    &.mat-accent,
    &.mat-warn {
      --mdc-checkbox-selected-checkmark-color: #{c.$color-background-background};

      --mdc-checkbox-unselected-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-checkbox-unselected-hover-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-checkbox-unselected-focus-icon-color: #{c.$color-foreground-secondary-text};
      --mdc-checkbox-unselected-pressed-icon-color: #{c.$color-foreground-secondary-text};
    }

    .mdc-form-field {
      display: grid;
      width: 100%;
      height: 100%;

      .mdc-checkbox,
      .mdc-label {
        // Stack checkbox and label on top of each other so that label covers the full click area (for easier click box manipulation)
        grid-row: 1;
        grid-column: 1;
      }

      .mdc-checkbox {
        margin-left: $click-box-left-padding;
      }

      .mdc-label {
        // Offset label content to the right of the radio via the padding (and add optional extra click box padding)
        $label-default-left-padding: $label-left-padding + $checkbox-size;
        $label-left-padding-with-extra-click-box: calc(#{$label-default-left-padding} + #{$click-box-left-padding});
        padding: $click-box-top-padding $click-box-right-padding $click-box-bottom-padding
          $label-left-padding-with-extra-click-box;

        width: 100%;
        height: 100%;
        box-sizing: border-box;

        &:empty {
          display: inline-block;
          padding: 0;

          width: calc(#{$click-box-left-padding} + #{$checkbox-size} + #{$click-box-right-padding});
          height: calc(#{$click-box-top-padding} + #{$checkbox-size} + #{$click-box-bottom-padding});
          min-width: 100%;
          min-height: 100%;
        }
      }
    }

    &.centered {
      .mdc-form-field {
        .mdc-checkbox {
          justify-self: center;
        }
      }
    }

    &.align-top {
      .mdc-form-field {
        .mdc-checkbox {
          align-self: start;
          margin-top: 4px;
        }
      }
    }

    .mdc-checkbox {
      padding: 0;

      .mat-mdc-checkbox-touch-target,
      .mat-mdc-checkbox-ripple,
      .mdc-checkbox__ripple {
        width: var(--mdc-checkbox-state-layer-size);
        height: var(--mdc-checkbox-state-layer-size);
      }

      .mdc-checkbox__native-control,
      .mdc-checkbox__background,
      .mat-mdc-checkbox-ripple,
      .mdc-checkbox__ripple {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .mdc-checkbox__ripple {
        display: var(--mat-checkbox-persistent-ripple-display, block);
      }
    }

    .mdc-label {
      @include m.text-truncate;
    }
  }
}
