@use 'material/custom-styles/button' as button-custom-styles;

@use 'colors' as c;
@use 'variables' as v;

@mixin news-item($theme) {
  $color-layout: c.$color-layout-500;

  $color-gray: c.$color-gray-500;

  .mp-news-item {
    .mat-expansion-panel.news-item-expansion-panel {
      border-radius: 4px;

      > mat-expansion-panel-header {
        height: auto;
        padding-block: v.$default-thickness;

        span.mat-content {
          height: 100%;
          margin: 0;
        }
      }
    }

    .news-header {
      &__title {
        color: $color-layout;
      }
    }

    .modification-info-label {
      font-weight: normal;
    }

    .news-actions {
      color: $color-gray;
    }
  }
}

:host {
  .news-header {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    &__title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__info {
      display: grid;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(200px, auto));
      grid-auto-rows: 1fr;
      column-gap: v.$default-thickness;
    }
  }

  .news-actions {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    height: 30px;

    &__edit-button {
      @include button-custom-styles.icon-button-size(30px, 18px);
    }
  }

  .creation-info-icon {
    min-width: 24px;
  }
}
