@use './colors/utilities' as color-utils;

@use 'colors/default-colors';
@forward 'colors/default-colors';

// Theme colors
$color-primary-10: var(--primary-10);
$color-primary-50: var(--primary-50);
$color-primary-100: var(--primary-100);
$color-primary-200: var(--primary-200);
$color-primary-300: var(--primary-300);
$color-primary-400: var(--primary-400);
$color-primary-500: var(--primary-500);
$color-primary-600: var(--primary-600);
$color-primary-700: var(--primary-700);
$color-primary-800: var(--primary-800);
$color-primary-900: var(--primary-900);

$color-primary-contrast-500: var(--primary-contrast-500);

$color-accent-50: var(--accent-50);
$color-accent-100: var(--accent-100);
$color-accent-200: var(--accent-200);
$color-accent-250: var(--accent-250);
$color-accent-300: var(--accent-300);
$color-accent-400: var(--accent-400);
$color-accent-500: var(--accent-500);
$color-accent-600: var(--accent-600);
$color-accent-700: var(--accent-700);
$color-accent-800: var(--accent-800);
$color-accent-900: var(--accent-900);

$color-accent-contrast-500: var(--accent-contrast-500);

$color-warn-50: var(--warn-50);
$color-warn-100: var(--warn-100);
$color-warn-200: var(--warn-200);
$color-warn-300: var(--warn-300);
$color-warn-400: var(--warn-400);
$color-warn-500: var(--warn-500);
$color-warn-600: var(--warn-600);
$color-warn-700: var(--warn-700);
$color-warn-800: var(--warn-800);
$color-warn-900: var(--warn-900);

$color-warn-contrast-500: var(--warn-contrast-500);

$color-layout-10: var(--layout-10);
$color-layout-50: var(--layout-50);
$color-layout-100: var(--layout-100);
$color-layout-200: var(--layout-200);
$color-layout-300: var(--layout-300);
$color-layout-400: var(--layout-400);
$color-layout-500: var(--layout-500);
$color-layout-600: var(--layout-600);
$color-layout-700: var(--layout-700);
$color-layout-800: var(--layout-800);
$color-layout-900: var(--layout-900);

$color-layout-contrast-500: var(--layout-contrast-500);

$color-background-status-bar: var(--background-status-bar);
$color-background-app-bar: var(--background-app-bar);
$color-background-background: var(--background-background);
$color-background-hover: var(--background-hover);
// NOTE: Background card color is hardcoded because it is the same value in all of the themes and Material does not support css variables on that. Using css variables would require a lot of overrides.
$color-background-card: white;
$color-background-dialog: var(--background-dialog);
$color-background-dialog-light: var(--background-dialog-light);
$color-background-disabled-button: var(--background-disabled-button);
$color-background-raised-button: var(--background-raised-button);
$color-background-focused-button: var(--background-focused-button);
$color-background-selected-button: var(--background-selected-button);
$color-background-selected-disabled-button: var(--background-selected-disabled-button);
$color-background-disabled-button-toggle: var(--background-disabled-button-toggle);
$color-background-unselected-chip: var(--background-unselected-chip);
$color-background-disabled-list-option: var(--background-disabled-list-option);
$color-background-table-header: var(--background-table-header);
$color-background-tooltip: var(--background-tooltip);

// NOTE: Foreground base color is hardcoded because it is the same value in all of the themes and Material does not support css variables on that. Using css variables would require a lot of overrides.
$color-foreground-base: black;
$color-foreground-divider: var(--foreground-divider);
$color-foreground-dividers: var(--foreground-dividers);
// NOTE: Foreground disabled color is hardcoded because it is the same value in all of the themes and Material does not support css variables on that. Using css variables would require a lot of overrides.
$color-foreground-disabled: rgba(0, 0, 0, 0.38);
$color-foreground-disabled-button: var(--foreground-disabled-button);
$color-foreground-disabled-text: var(--foreground-disabled-text);
// NOTE: Foreground elevation color is hardcoded because it is the same value in all of the themes and Material does not support css variables on that. Using css variables would require a lot of overrides.
$color-foreground-elevation: black;
$color-foreground-hint-text: var(--foreground-hint-text);
$color-foreground-secondary-text: var(--foreground-secondary-text);
$color-foreground-icon: var(--foreground-icon);
$color-foreground-icons: var(--foreground-icons);
$color-foreground-text: var(--foreground-text);
$color-foreground-slider-min: var(--foreground-slider-min);
$color-foreground-slider-off: var(--foreground-slider-off);
$color-foreground-slider-off-active: var(--foreground-slider-off-active);

// App header colors
$color-app-header-text: var(--layout-contrast-700);

$color-app-header-element-base-color: rgb(255, 255, 255);
$color-app-header-elements-hover-alpha: 0.2;
$color-app-header-elements-border-alpha: 0.1;

$color-app-header-hover: rgba($color-app-header-element-base-color, $color-app-header-elements-hover-alpha);
$color-app-header-elements-border: rgba($color-app-header-element-base-color, $color-app-header-elements-border-alpha);

// App loader spinner colors
$color-app-loader-background: #fbfbfb;
$color-app-loader-foreground: #cccccc;

// Table colors
$table-border-color: $color-layout-50;

// Tabs colors
$tab-active-color: $color-accent-500;
$tab-inactive-color: default-colors.$color-gray-500;
$tab-ripple-color: default-colors.$color-gray-500;

// Dropdown/Select/Autocomplete colors
$dropdown-selected-value-color: rgba(0, 0, 0, 0.87);
$dropdown-icon-color: $color-layout-400;
$dropdown-active-color: $color-primary-500;
$dropdown-invalid-color: $color-warn-500;
$dropdown-disabled-color: $color-foreground-disabled-text;

$option-color: default-colors.$color-gray-600;
$option-active-color: $color-primary-500;
$option-background-color: #f7fafa;

// Input colors
$input-placeholder-color: color-utils.add-opacity-to-color-variable($color-foreground-secondary-text, 0.42);

// Form field colors
$form-field-text-color: rgba(0, 0, 0, 0.87);
$form-field-caret-color: $color-primary-500;
$form-field-label-color: $color-layout-500;
$form-field-background-color: $color-layout-500;
$form-field-underline-color: $color-layout-500;
$form-field-underline-ripple-color: $color-primary-500;
