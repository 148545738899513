@use 'core/loading-spinner';
@use 'colors' as c;

@mixin mp-spinner-theme {
  .mp-spinner {
    color: c.$color-layout-500;

    .spinner {
      > div {
        background: currentColor;
      }
    }
  }
}

:host {
  @include mp-spinner-theme;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner {
    @include loading-spinner.spinner-common();

    > div {
      margin: 1px;
    }
  }
}
