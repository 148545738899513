@mixin dialog-default-styles {
  .mat-mdc-dialog-container {
    --mdc-dialog-supporting-text-weight: normal;
    --mdc-dialog-supporting-text-color: black;
    --mdc-dialog-supporting-text-size: 16px;
  }

  .mat-mdc-dialog-title.mdc-dialog__title::before {
    display: none;
  }
}
