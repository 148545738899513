@use 'colors' as c;

@mixin progress-bar-default-styles {
  .mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: #{c.$color-primary-100};

    &.mat-accent {
      --mdc-linear-progress-track-color: #{c.$color-accent-100};
    }

    &.mat-warn {
      --mdc-linear-progress-track-color: #{c.$color-warn-100};
    }
  }
}
