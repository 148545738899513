@use 'material/custom-styles/chips' as chips-custom-styles;

@use 'colors' as c;
@use 'variables' as v;

@mixin entity-item($theme) {
  $accent-color: c.$color-accent-500;
  $color-layout: c.$color-layout-500;

  $item-border-radius: 4px;
  $item-remove-background: #a1a1a1;

  $color-text-gray: #757575;

  $item-border-radius: 4px;
  $item-background--removed: #a1a1a1;

  $font-label: 700 #{16px}/#{32px} Roboto;
  $font-meta: 300 #{14px}/#{30px} Roboto;

  $vgap: 12px;

  .mp-entity-item {
    display: block;
    background: #fff;
    border-radius: $item-border-radius;
    padding: 7px v.$default-thickness * 0.5 7px v.$default-thickness;
    margin: 0 0 v.$default-thickness * 0.5 0;
    box-sizing: border-box;

    border: 1px rgb(0 0 0 / 5%) solid;
    border-bottom: 1px rgb(0 0 0 / 15%) solid;

    display: flex;
    flex-wrap: nowrap;

    .entity-item__icon {
      flex: 0 0 20px;
      align-self: center;
      height: 32px;
      line-height: 28px;
      color: $color-layout;
      margin-right: $vgap;
      min-width: 0;

      .mat-icon {
        font-size: 20px;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }

    .entity-item__label {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      white-space: nowrap;
      min-width: 0;
      width: 100%;

      &-text {
        font: $font-label;
        color: $color-layout;
        margin: 0;
        padding: 0;
        margin-right: $vgap;
      }

      &-meta {
        font: $font-meta;
        color: $color-text-gray;
        padding-top: 2px;

        mat-chip-set {
          display: block;
          margin-top: 2px;

          mat-chip {
            @include chips-custom-styles.chip-item-height(24px);
            @include chips-custom-styles.chip-item-font-size(14px);
            @include chips-custom-styles.chip-item-color($color-text-gray);
          }
        }
      }
    }

    .entity-item__actions {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }
  }

  .mp-entity-item.item--removed {
    opacity: 0.5;

    .mat-icon {
      color: $item-remove-background;
    }

    .entity-item__label {
      .entity-item__label-text,
      .entity-item__label-meta {
        color: $item-remove-background;
        text-decoration: line-through;
      }
    }
  }

  .mp-entity-item.item--hidden {
    display: none;
    visibility: hidden;
  }
}
