@use 'colors/utilities' as color-utils;

@use 'colors' as c;

@mixin select-default-styles {
  mat-select.mat-mdc-select {
    --mat-select-placeholder-text-color: #{c.$input-placeholder-color};

    // Inherit font properties from usage context
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;

    --mat-select-enabled-trigger-text-color: #{c.$dropdown-selected-value-color};
    --mat-select-enabled-arrow-color: #{c.$dropdown-icon-color};
  }

  .mat-mdc-select-trigger {
    height: 100%;
    box-sizing: border-box;
    padding: var(--mat-select-trigger-padding, 0);
  }

  .mat-mdc-select-arrow-wrapper {
    .mat-mdc-select-arrow {
      width: 24px;
      margin-inline: 0;
    }
  }

  .mat-mdc-select-panel {
    // Important is needed as there is no easy and clear way to make the selector more specific due to the panel structure
    padding: 0 !important;
  }
}
