@use 'material/custom-styles/dialog' as dialog-custom-styles;

@mixin upload-attachment-dialog-global {
  .mpctm-upload-attachment-dialog-container {
    .mat-mdc-dialog-container {
      @include dialog-custom-styles.dialog-container-background-color(white);
      max-width: 640px;
    }
  }
}
