@use 'material/custom-styles/tooltip' as tooltip-custom-styles;

@use 'colors' as c;

@mixin app-layout-global {
  .mp-app-layout {
    &__navigation-menu-tooltip {
      @include tooltip-custom-styles.tooltip-background-color(c.$color-layout-500);
    }
  }
}
