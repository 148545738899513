@use 'colors' as c;

@mixin filters-context-area-global {
  .mp-filters-context-area {
    .context-area-card {
      > :not(:first-child) {
        border-top: 1px solid c.$color-layout-50;
      }
    }
  }
}
