@use 'colors' as c;
@use 'variables' as v;

@mixin favorites-list-item($theme) {
  $color-accent: c.$color-accent-500;

  $color-gray-light: #a6a6a6;
  $color-gray: #757575;
  $background-color: #f6f6f6;

  .mpcm-favorites-list-item {
    &--active {
      .favorites-list {
        &__name {
          color: $color-accent;
        }
      }
    }

    .favorites-list {
      color: $color-gray;
      background: $background-color;

      &__count {
        color: $color-gray-light;
        padding: 0 v.$default-thickness * 0.5;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
      }
    }
  }
}

:host {
  .favorites-list {
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 19px;

    &__name {
      flex-grow: 1;
      font-weight: 700;
    }
  }
}
