@use 'material/custom-styles/radio' as radio-custom-styles;

@use 'colors' as c;
@use 'mixins' as m;
@use 'variables' as v;

@mixin article-details-offers-theme($theme) {
  $color-layout: c.$color-layout-500;
  $color-gray: c.$color-gray-600;
  $color-lightgray: #f6f6f6;

  $border-color: #dfdfdf;
  $option-background: c.$color-layout-10;

  $hover-background: c.$color-background-hover;

  $color-accent: c.$color-accent-500;

  $font-title: 700 #{16px}/#{20px} 'Roboto';
  $font-subtitle: 600 #{14px}/#{20px} 'Roboto';
  $font-info: 300 #{14px}/#{20px} 'Roboto';

  .mpcm-article-details-offers {
    .offers {
      .mat-expansion-panel-body {
        overflow: hidden;
      }

      &__header {
        .mat-expansion-indicator::after {
          box-sizing: border-box;
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $color-gray transparent transparent transparent;
          content: '';
          padding: 0;
          transform: none;
          width: 10px;
          height: 10px;
          font-size: 0;
        }
      }

      &__title {
        color: $color-layout !important;
        font: $font-subtitle;
        text-transform: uppercase;
      }

      &__count {
        text-align: center;
        height: 18px;
        margin-right: 8px;

        padding: 0 8px;

        border-radius: 2px;

        font: $font-subtitle;
        line-height: 18px;

        color: $color-layout;
        background-color: $color-lightgray;
      }
    }

    .offer-card {
      background-color: $color-lightgray;
      border-radius: 4px;
      border: 2px solid $color-lightgray;

      &--selected {
        border-color: c.$color-primary-100;
      }
    }

    .offer-row {
      &:hover {
        background-color: $hover-background;
      }

      &__title {
        font: $font-title;
        color: $color-gray;
      }

      &__infos,
      &__supplier-selector__field {
        font: $font-info;
        color: $color-gray;

        &__article-number {
          color: $color-gray;
        }
      }

      &__type-icon {
        font-size: 20px;
        color: $color-gray;
      }

      &.selected {
        background-color: #fff;

        .offer-row__title,
        .offer-row__infos {
          color: $color-layout;
        }
      }
    }
  }

  .mpcm-article-details-offers-supplier-option {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 14px;

    .mpcm-article-details-offers-supplier-option__icon {
      flex: 0 0 18px;
      @include m.icon-size(18px);
    }
  }
}
